import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { useRowStyles } from "../../../styles/stylesRows";

export const RowHistorial = ({ item, index }) => {
  const classes = useRowStyles();
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${item.error === true ? classes.celdaEliminado : ""}`;
  return (
    <TableRow id="scroll-dialog-description" tabIndex={-1} mb={1}>
      <TableCell
        align="center"
        className={claseCelda}
        sx={{ minHeight: "1rem !important", fontSize: ".9rem !important" }}
      >
        {item.nombre_persona}
      </TableCell>
      <TableCell
        align="center"
        className={claseCelda}
        sx={{
          minHeight: "1rem !important",
          fontSize: ".9rem !important",
          color:
            item.estado === "ANULADO"
              ? "red"
              : item.estado === "EN PROCESO"
              ? "blue"
              : item.estado === "PROCESADO"
              ? "green"
              : item.estado === "PENDIENTE"
              ? "orange"
              : "",
        }}
      >
        {item.estado}
      </TableCell>
      <TableCell
        align="center"
        className={claseCelda}
        sx={{ minHeight: "1rem !important", fontSize: ".9rem !important" }}
      >
        {new Date(item.fec_reg).toLocaleString("es-ES", {
          weekday: "short",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour: "numeric",
        })}
      </TableCell>
    </TableRow>
  );
};

import { makeStyles } from "@mui/styles";
export const useModalStyle = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "65%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2),
    borderRadius: "15px",
    marginTop: "4vh",
    [theme.breakpoints.down("xl")]: {
      width: "80%",
      marginTop: "",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.2, 0.4),
      width: "100%",
      marginTop: "",
    },
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    maxHeight: "68vh",
    overflow: "scroll",
    alignContent: "stretch",
  },
  ocultar: {
    display: "none",
  },

  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.2),
    margin: theme.spacing(0.2),
    width: "40%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formD25: {
    flexGrow: 1,
    padding: theme.spacing(0.2),
    margin: theme.spacing(0.2),
    width: "22%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.1),
    margin: theme.spacing(0.1),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  formAllWidth: {
    flexGrow: 1,
    width: "100%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  error: {
    color: "red",
    fontWeight: "bold",
  },
  buttonTab: {
    padding: "6px",
    flexGrow: "1",
    borderRadius: "5px 5px 0px 0px",
  },
}));

import React, { useState, useContext, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  TextField,
  Avatar,
  FormControl,
  Autocomplete,
  MenuItem,
  Grid,
  IconButton,
  Collapse,
} from "@mui/material";
import Draggable from "react-draggable";
import {
  AddCircle,
  Delete,
  AddCircleOutlineTwoTone,
  Restore,
  Save,
  Close,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { minFecha, trimPropsItem } from "../../../../config/const";
import clienteAxios from "../../../../config/axios";
import { usePermiso } from "../../../../hooks/usePermiso";
import { useTheme } from "@emotion/react";
import { agregarTransparencia } from "../../../../helpers";
import siluetaPet from "../../../../assets/img/siluetaPet.jpg";
import { TimelineComponent } from "./TimelineComponent";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: 0,
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: { width: "90%", marginTop: "2vh" },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: { display: "none" },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    width: "43%",
    padding: "0",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "4.%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },

  form30PC: {
    flexGrow: 1,
    width: "30%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },

  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": { opacity: ".7" },
  },
  center: { justifyContent: "center", alignItems: "center" },
  root: { width: "100%", marginTop: theme.spacing(1) },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: { color: "red", fontWeight: "bold" },
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    marginTop: theme.spacing(1),
    overflowY: "scroll",
    overflowX: "hidden",
    maxHeight: "65vh",
    [theme.breakpoints.down("md")]: {
      maxHeight: "45vh",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "40vh",
    },
  },
  container2: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    marginTop: theme.spacing(1),
    overflowY: "scroll",
    overflowX: "hidden",
    maxHeight: "65vh",
    [theme.breakpoints.down("md")]: {
      maxHeight: "65vh",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "65vh",
    },
  },
}));
const ModalData = (props) => {
  const { mostrarAlerta } = useContext(AlertaContext);
  const { tienePermiso, alertaPermiso } = usePermiso("Mascotas");
  const tema = useTheme();
  const { usuario } = useContext(AuthContext); //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    ObjectoActivo,
    changeObjetoActivoState,
    // OTRO LADO
    setopenEspecies,
    // setopenAlimentos,
    setopenSexo,
    setopenPropietario,
    openEspecies,
    openAlimentos,
    openSexo,
    socket,
    changeObjetoActivoStatePropietario,
    setopenPropietarioModal,
  } = props;
  // eslint-disable-next-line
  const [item, setitem] = useState(ObjectoActivo);
  const {
    mascPerNombre,
    mascNombre,
    mascEspecie,
    mascRaza,
    mascAlimento,
    mascTipoAlimento,
    mascSexo,
    mascColor,
    mascFechaNacimiento,
    mascEstado,
    mascObservacion,
    mascMicroChip,
    mascCaracter,
    mascContactoExtra,
    mascPerDocNumero,
    mascPeso,
    nombre_medico_tratante,
  } = item;
  const unDia = 1000 * 60 * 60 * 24;
  const [años, setaños] = useState(0);
  const [meses, setmeses] = useState(0);
  const [dias, setdias] = useState(0);
  const [archivo, setarchivo] = useState("");
  const [imagenMostrada, setimagenMostrada] = useState(null);
  const onChangeFile = (e) => {
    if (!e.target.files[0]) {
      setimagenMostrada(null);
      return setarchivo("");
    }

    setarchivo(e.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e) => {
      e.preventDefault();
      setimagenMostrada(e.target.result);
    };
  };
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  const [cargando, setcargando] = useState(false);
  const [seccionActive, setseccionActive] = useState({
    datosSeccion: true,
    contactoExtraSeccion: false,
    observacionSeccion: false,
    timeline: false,
  });
  const {
    datosSeccion,
    contactoExtraSeccion,
    observacionSeccion,
    timeline,
  } = seccionActive;
  const [error, seterror] = useState({});
  const [personasData, setpersonasData] = useState([]);
  const [veterinariosData, setveterinariosData] = useState([]);
  const [especiesData, setEspeciesData] = useState(null);
  const [razasData, setrazasData] = useState([]);
  // GET => /api/especies/filtro/0
  const [alimentosData, setAlimentosData] = useState([]);
  const [tipoAlimentoData, settipoAlimentoData] = useState([]);
  // GET => /api/alimentos/filtro/0
  const [sexoData, setSexoData] = useState(null);
  // GET => /api/sexo/filtro/0
  useEffect(() => {
    const getespeciesData = async () => {
      try {
        const resespeciesData = await clienteAxios.get(`/especies/filtro/0`);

        setEspeciesData(resespeciesData.data.data);
      } catch (error) {
        setEspeciesData([]);
      }
    };

    if (open) {
      getespeciesData();
    }
    // const getalimentosData = async () => {
    //   try {
    //     const resalimentosData = await clienteAxios.get(`/alimentos/filtro/0`);

    //     setAlimentosData(resalimentosData.data.data);
    //   } catch (error) {
    //     setAlimentosData([]);
    //   }
    // };

    // if (open) {
    //   getalimentosData();
    // }
    const getsexoData = async () => {
      try {
        const ressexoData = await clienteAxios.get(`/sexo/filtro/0`);

        setSexoData(ressexoData.data.data);
      } catch (error) {
        setSexoData([]);
      }
    };

    if (open) {
      getsexoData();
    }
    // eslint-disable-next-line
  }, [openEspecies, openAlimentos, openSexo, open]);
  useEffect(() => {
    if (especiesData) {
      const resEspecie = especiesData.find((item) => {
        return item.espNombre === ObjectoActivo.mascEspecie;
      });
      if (resEspecie) {
        setAlimentosData(resEspecie.espAlimentos);

        const itemF = resEspecie.espAlimentos.find((itemAlimento) => {
          return itemAlimento.nombre === ObjectoActivo.mascAlimento;
        });

        if (itemF) {
          settipoAlimentoData(itemF.tiposAlimento);
        }
      }
    }

    setitem(ObjectoActivo);
    // eslint-disable-next-line
  }, [open, especiesData]);

  const defaultActive = () => {
    changeObjetoActivoState({
      mascNombre: "",
      mascIdMedicoTratante: "",
      nombre_medico_tratante: "",
      mascEspecie: "",
      mascRaza: "",
      mascAlimento: "",
      mascTipoAlimento: "",
      mascSexo: "",
      mascPeso: "",
      mascColor: "",
      mascFechaNacimiento: "",
      mascEdad: "",
      mascFoto: "",
      mascEstado: "",
      mascEliminado: "",
      mascQuienElimina: "",
      mascMicroChip: "",
      mascCaracter: "",
      mascObservacion: "",
      mascPerId: "",
      mascPerNombre: "",
      mascPerTelefono: "",
      mascPerDocNumero: "",
      mascPerDireccion: "",
      mascPerCelular: "",
      mascPerEmail: "",
      mascContactoExtra: [
        // {
        //   nombre: "JC",
        //   celular: "59387654321",
        //   correo: "jc@gmail.com",
        // },
      ],
      mascUser: "",
      mascEmpresa: "",
      mascFecReg: "",
      mascFecModificacion: "",
      Todos: "",
      mascHistorialPropietarios: [],
    });
    setopen(false);
    seteditar(false);
    seterror({});

    setpersonasData([]);
    setEspeciesData(null);
    setrazasData([]);
    setAlimentosData([]);
    settipoAlimentoData([]);
    setSexoData(null);
    setseccionActive({
      datosSeccion: true,
      contactoExtraSeccion: false,
      observacionSeccion: false,
      fotoSeccion: false,
    });
    setarchivo("");
    setimagenMostrada(null);
    setdias(0);
    setmeses(0);
    setaños(0);
    setcargando(false);
  };
  const [inCollapse, setinCollapse] = useState(true);
  //hook de estilos
  const clases = useStyles();
  const funcionEditPropietario = async () => {
    try {
      const res = await clienteAxios(`/proveedores/${item.mascPerId}`);
      if (res.data.data.length === 0) {
        return mostrarAlerta(
          "Hubo un error en la consulta del propietario",
          "error"
        );
      }
      changeObjetoActivoStatePropietario(res.data.data[0]);
      setopenPropietarioModal(true);
      // setopen(true);
      // seteditar(true);
      // changeObjetoActivoState(item);
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
    }
  };

  //definiendo los estilos del modal
  const validacion = () => {
    console.log(nombre_medico_tratante);
    if (
      mascPerNombre.trim() === "" ||
      mascNombre.trim() === "" ||
      mascEspecie.trim() === "" ||
      mascRaza.trim() === "" ||
      mascAlimento.trim() === "" ||
      mascSexo.trim() === "" ||
      mascColor.trim() === "" ||
      mascFechaNacimiento.trim() === "" ||
      mascEstado.trim() === "" ||
      (nombre_medico_tratante === "" && usuario.rucempresa === "MEDIPET")
    ) {
      seterror({
        mascPerNombre: mascPerNombre.trim() === "" ? true : false,
        mascNombre: mascNombre.trim() === "" ? true : false,
        mascEspecie: mascEspecie.trim() === "" ? true : false,
        mascRaza: mascRaza.trim() === "" ? true : false,
        mascAlimento: mascAlimento.trim() === "" ? true : false,
        mascSexo: mascSexo.trim() === "" ? true : false,
        mascColor: mascColor.trim() === "" ? true : false,
        mascFechaNacimiento: mascFechaNacimiento.trim() === "" ? true : false,
        mascEstado: mascEstado.trim() === "" ? true : false,
        nombre_medico_tratante:
          nombre_medico_tratante === "" && usuario.rucempresa === "MEDIPET",
      });

      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);
      let resFoto = null;
      if (archivo !== "") {
        const formDataLogos = new FormData();
        formDataLogos.append("tipo", "mascotas");
        formDataLogos.append("rucempresa", usuario.rucempresa);
        formDataLogos.append("archivoAnterior", item.mascFoto);
        formDataLogos.append("archivo", archivo);
        resFoto = await clienteAxios.post(
          "/upload_delete_multiple_files/upload",
          formDataLogos
        );
      }

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          mascEliminado: "NO",
          rol: usuario.rol,
          tabla: "mascota",
          mascUser: usuario.usuario,
          mascEmpresa: usuario.rucempresa,
          mascFoto: resFoto ? resFoto.data.nombre : item.mascFoto,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);
      let resFoto = null;
      if (archivo !== "") {
        const formDataLogos = new FormData();
        formDataLogos.append("tipo", "mascotas");
        formDataLogos.append("rucempresa", usuario.rucempresa);
        formDataLogos.append("archivoAnterior", item.mascFoto);
        formDataLogos.append("archivo", archivo);
        resFoto = await clienteAxios.post(
          "/upload_delete_multiple_files/upload",
          formDataLogos
        );
      }

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "mascota",
          mascUser: `${item.mascUser.split(" ** ")[0]} ** ${usuario.usuario}`,
          mascEmpresa: usuario.rucempresa,
          mascFoto: resFoto ? resFoto.data.nombre : item.mascFoto,
          mascHistorialPropietarios: [
            ...new Set([
              ...item.mascHistorialPropietarios,
              Number(item.mascPerId),
            ]),
          ],
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  //filas por pagina

  // 1 + 2 + 3 + 4

  if (!open) {
    return null;
  }
  if (!especiesData) {
    return null;
  }

  if (!sexoData) {
    return null;
  }
  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#modalMascota">
          <Box display="flex" justifyContent="center">
            <div
              style={{
                borderRadius: "15px",
                position: "absolute",
              }}
              className={clases.paper}
            >
              <Grid container sx={{ width: "100%" }}>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  sx={{
                    background: agregarTransparencia(
                      tema.palette.secondary.light,
                      0.1
                    ),
                    borderRight: `1px solid ${agregarTransparencia(
                      tema.palette.secondary.light,
                      0.25
                    )}`,
                    p: { sx: 0, sm: 2 },
                  }}
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDirection={"column"}
                >
                  <Box
                    display="flex"
                    width="100%"
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <input
                      id="contained-button-file"
                      className={clases.ocultar}
                      type="file"
                      name="myImage"
                      onChange={onChangeFile}
                    />

                    {/* //si un archivo exoste  */}
                    {archivo ? (
                      <Box
                        display="flex"
                        flexDirection="column"
                        className={clases.center}
                      >
                        <label htmlFor="contained-button-file">
                          <Avatar
                            htmlFor="contained-button-file"
                            alt="Mascota"
                            src={imagenMostrada ? imagenMostrada : siluetaPet}
                            className={clases.large}
                          />
                        </label>
                        <Typography
                          variant="body1"
                          color="initial"
                          align="center"
                        >
                          {archivo.name}
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        className={clases.center}
                      >
                        <label htmlFor="contained-button-file">
                          <Avatar
                            htmlFor="contained-button-file"
                            alt="Logo"
                            src={item.mascFoto ? item.mascFoto : siluetaPet}
                            className={clases.large}
                          />
                        </label>
                        <Typography
                          variant="body1"
                          color="initial"
                          align="center"
                        >
                          Seleccione una foto
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box height={"100%"} width={"100%"}>
                    <Collapse in={inCollapse} sx={{ height: "100%" }}>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        height={"100%"}
                        justifyItems={"space-between"}
                      >
                        <Box mt={1} width={"100%"}>
                          <Button
                            fullWidth
                            variant={datosSeccion ? "contained" : "outlined"}
                            disableElevation
                            style={{ padding: "8px" }}
                            color={
                              error.mascPerNombre ||
                              error.mascNombre ||
                              error.mascEspecie ||
                              error.mascRaza ||
                              error.mascAlimento ||
                              error.mascTipoAlimento ||
                              error.mascSexo ||
                              error.mascColor ||
                              error.mascFechaNacimiento ||
                              error.mascEstado
                                ? "error"
                                : "primary"
                            }
                            onClick={() =>
                              setseccionActive({ datosSeccion: true })
                            }
                          >
                            Datos
                          </Button>

                          <Button
                            fullWidth
                            variant={
                              contactoExtraSeccion ? "contained" : "outlined"
                            }
                            disableElevation
                            style={{ padding: "8px" }}
                            color={false ? "error" : "primary"}
                            onClick={() =>
                              setseccionActive({ contactoExtraSeccion: true })
                            }
                          >
                            Contactos extras
                          </Button>
                          <Button
                            fullWidth
                            variant={observacionSeccion ? "contained" : "outlined"}
                            disableElevation
                            style={{ padding: "8px" }}
                            color={
                              error.nombre_medico_tratante
                                ? "error"
                                : "primary"
                            }
                            onClick={() =>
                              setseccionActive({ observacionSeccion: true })
                            }
                          >
                            Observación
                          </Button>

                          {editar && (
                            <Button
                              fullWidth
                              variant={timeline ? "contained" : "outlined"}
                              disableElevation
                              style={{ padding: "8px" }}
                              color={false ? "error" : "primary"}
                              onClick={() =>
                                setseccionActive({ timeline: true })
                              }
                            >
                              Timeline
                            </Button>
                          )}
                        </Box>
                        {editar && (
                          <Button
                            fullWidth
                            variant={"text"}
                            disableElevation
                            style={{ padding: "8px", marginTop: ".2rem" }}
                            color={"secondary"}
                            onClick={() => {
                              funcionEditPropietario();
                            }}
                          >
                            Propietario
                          </Button>
                        )}
                      </Box>
                    </Collapse>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={9} p={2} pt={1}>
                  <>
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      flexWrap={"wrap"}
                    >
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        id="modalMascota"
                        sx={{ cursor: "grab" }}
                      >
                        {editar ? "Editar" : "Crear"} Mascota
                        {" - "}
                        <Typography
                          variant="subtitle2"
                          color="secondary"
                          component={"span"}
                        >
                          {seccionActive.datosSeccion && "Datos"}
                          {seccionActive.contactoExtraSeccion &&
                            "Contacto Extra"}
                          {seccionActive.observacionSeccion && "Observación"}
                        </Typography>
                      </Typography>

                      <Box>
                        <IconButton
                          sx={{ display: { sm: "none", sx: "inline-block" } }}
                          color={item.restaurado ? "success" : "secondary"}
                          disabled={cargando}
                          onClick={() => {
                            setinCollapse(!inCollapse);
                          }}
                        >
                          {inCollapse ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        <IconButton
                          color={item.restaurado ? "success" : "secondary"}
                          disabled={cargando}
                          onClick={() => {
                            if (editar) {
                              if (!tienePermiso("editar")) {
                                return alertaPermiso("editar");
                              }
                              handleEditar();
                            } else {
                              if (!tienePermiso("agregar")) {
                                return alertaPermiso("agregar");
                              }
                              handleCrear();
                            }
                          }}
                        >
                          {item.restaurado ? <Restore /> : <Save />}
                        </IconButton>
                        <IconButton
                          color={"error"}
                          disabled={cargando}
                          onClick={() => {
                            defaultActive();
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider />
                    <Box
                      height={"90%"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"space-between"}
                    >
                      <Box
                        className={
                          inCollapse ? clases.container : clases.container2
                        }
                      >
                        <>
                          {datosSeccion ? (
                            <>
                              <FormControl fullWidth className={clases.formD}>
                                <Autocomplete
                                  filterOptions={(x) => x}
                                  options={personasData.map((item) => {
                                    return (
                                      item.perNombre +
                                      "/+/" +
                                      item.perDocNumero +
                                      "/+/" +
                                      item.perId
                                    );
                                  })}
                                  getOptionLabel={(option) => {
                                    return option
                                      .replace("/+/", " ")
                                      .split("/+/")[0];
                                  }}
                                  id="controlled-demo"
                                  value={
                                    (
                                      mascPerNombre +
                                      " " +
                                      mascPerDocNumero
                                    ).trim() === ""
                                      ? ""
                                      : mascPerNombre + " " + mascPerDocNumero
                                  }
                                  paperprops
                                  onChange={async (event, newValue) => {
                                    if (newValue) {
                                      const options = newValue.split("/+/");
                                      const itemF = personasData.find(
                                        (itemPersona) => {
                                          return (
                                            itemPersona.perId ===
                                            Number(options[2])
                                          );
                                        }
                                      );

                                      setitem({
                                        ...item,
                                        mascPerId: itemF.perId,
                                        mascPerNombre: itemF.perNombre,
                                        mascPerTelefono: itemF.perTelefono,
                                        mascPerDocNumero: itemF.perDocNumero,
                                        mascPerDireccion: itemF.perDireccion,
                                        mascPerCelular: itemF.perCelular,
                                        mascPerEmail: itemF.perEmail,
                                      });
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size="small"
                                      error={error.mascPerNombre}
                                      label="Propietario"
                                      fullWidth
                                      onChange={async (e) => {
                                        if (e.target.value === "") {
                                          return;
                                        }
                                        try {
                                          const res = await clienteAxios.get(
                                            `/proveedores/filtro/0?search=${e.target.value}&estado=CLIENTE`
                                          );
                                          if (!res.data.data) {
                                            return mostrarAlerta(
                                              res.data.msg,
                                              "error"
                                            );
                                          }
                                          setpersonasData(res.data.data);
                                        } catch (error) {}
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            <IconButton
                                              size="small"
                                              aria-label=""
                                              onClick={async () => {
                                                setopenPropietario(true);
                                              }}
                                            >
                                              <AddCircleOutlineTwoTone
                                                color="secondary"
                                                fontSize="small"
                                              />
                                            </IconButton>

                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                              <TextField
                                className={clases.formD}
                                label="Nombre"
                                value={mascNombre}
                                name="mascNombre"
                                error={error.mascNombre}
                                onChange={(e) => handleChange(e)}
                              />
                              <FormControl fullWidth className={clases.formD}>
                                <Autocomplete
                                  options={especiesData.map((item) => {
                                    return item.espNombre + "/+/" + item.espId;
                                  })}
                                  getOptionLabel={(option) => {
                                    return option.split("/+/")[0];
                                  }}
                                  id="controlled-demo"
                                  value={mascEspecie}
                                  paperprops
                                  onChange={async (event, newValue) => {
                                    if (newValue) {
                                      const options = newValue.split("/+/");
                                      const itemF = especiesData.find(
                                        (itemEspecie) => {
                                          return (
                                            itemEspecie.espId ===
                                            Number(options[1])
                                          );
                                        }
                                      );
                                      setAlimentosData(itemF.espAlimentos);
                                      settipoAlimentoData([]);
                                      setrazasData(itemF.espRazas);
                                      setitem({
                                        ...item,
                                        mascEspecie: options[0],
                                        mascRaza: "",
                                        mascAlimento: "",
                                        mascTipoAlimento: "",
                                      });
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size="small"
                                      error={error.mascEspecie}
                                      label="Especie"
                                      fullWidth
                                      onChange={async (e) => {
                                        if (e.target.value === "") {
                                          return;
                                        }
                                        try {
                                          // const res = await clienteAxios.get();
                                          // if (!res.data.data) {
                                          //   return mostrarAlerta(res.data.msg, "error");
                                          // }
                                          // setdata(res.data.data);
                                        } catch (error) {}
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            <IconButton
                                              size="small"
                                              aria-label=""
                                              onClick={async () => {
                                                setopenEspecies(true);
                                              }}
                                            >
                                              <AddCircleOutlineTwoTone
                                                color="secondary"
                                                fontSize="small"
                                              />
                                            </IconButton>

                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                              <FormControl fullWidth className={clases.formD}>
                                <Autocomplete
                                  options={razasData.map((item) => {
                                    return item.nombre;
                                  })}
                                  getOptionLabel={(option) => {
                                    return option.replace("/+/", " ");
                                  }}
                                  id="controlled-demo"
                                  value={mascRaza}
                                  paperprops
                                  onChange={async (event, newValue) => {
                                    if (newValue) {
                                      setitem({
                                        ...item,
                                        mascRaza: newValue,
                                      });
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size="small"
                                      error={error.mascRaza}
                                      label="Raza"
                                      fullWidth
                                      onChange={async (e) => {
                                        if (e.target.value === "") {
                                          return;
                                        }
                                        try {
                                          // const res = await clienteAxios.get();
                                          // if (!res.data.data) {
                                          //   return mostrarAlerta(res.data.msg, "error");
                                          // }
                                          // setdata(res.data.data);
                                        } catch (error) {}
                                      }}
                                      // InputProps={{
                                      //   ...params.InputProps,
                                      //   endAdornment: (
                                      //     <React.Fragment>
                                      //       {!editar ? (
                                      //         <IconButton
                                      //           size="small"
                                      //           aria-label=""
                                      //           onClick={async () => {
                                      //             seteditarProveedor(false);
                                      //             setopenProveedor(true);
                                      //           }}
                                      //         >
                                      //           <AddCircleOutlineTwoTone color="primary"  fontSize="small"/>
                                      //         </secondary>
                                      //       ) : null}
                                      //       {params.InputProps.endAdornment}
                                      //     </React.Fragment>
                                      //   ),
                                      // }}
                                    />
                                  )}
                                />
                              </FormControl>
                              {/* <TextField
                        className={clases.formD}
                        label="Raza"
                        value={mascRaza}
                        name="mascRaza"
                        error={error.}
                        onChange={(e) => handleChange(e)}
                      /> */}
                              <FormControl fullWidth className={clases.formD}>
                                <Autocomplete
                                  options={alimentosData.map((item) => {
                                    return item.nombre;
                                  })}
                                  getOptionLabel={(option) => {
                                    return option.replace("/+/", " ");
                                  }}
                                  id="controlled-demo"
                                  value={mascAlimento}
                                  paperprops
                                  onChange={async (event, newValue) => {
                                    if (newValue) {
                                      const options = newValue.split("/+/");
                                      const itemF = alimentosData.find(
                                        (itemAlimento) => {
                                          return (
                                            itemAlimento.nombre === newValue
                                          );
                                        }
                                      );

                                      settipoAlimentoData(itemF.tiposAlimento);
                                      setitem({
                                        ...item,
                                        mascAlimento: options[0],
                                        mascTipoAlimento: "",
                                      });
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size="small"
                                      error={error.mascAlimento}
                                      label="Alimento"
                                      fullWidth
                                      onChange={async (e) => {
                                        if (e.target.value === "") {
                                          return;
                                        }
                                        try {
                                          // const res = await clienteAxios.get();
                                          // if (!res.data.data) {
                                          //   return mostrarAlerta(res.data.msg, "error");
                                          // }
                                          // setdata(res.data.data);
                                        } catch (error) {}
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                              <FormControl fullWidth className={clases.formD}>
                                <Autocomplete
                                  options={tipoAlimentoData.map((item) => {
                                    return item.nombre;
                                  })}
                                  getOptionLabel={(option) => {
                                    return option.replace("/+/", " ");
                                  }}
                                  id="controlled-demo"
                                  value={mascTipoAlimento}
                                  paperprops
                                  onChange={async (event, newValue) => {
                                    if (newValue) {
                                      setitem({
                                        ...item,
                                        mascTipoAlimento: newValue,
                                      });
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size="small"
                                      error={error.mascTipoAlimento}
                                      label="Tipo Alimento"
                                      fullWidth
                                      onChange={async (e) => {
                                        if (e.target.value === "") {
                                          return;
                                        }
                                        try {
                                          // const res = await clienteAxios.get();
                                          // if (!res.data.data) {
                                          //   return mostrarAlerta(res.data.msg, "error");
                                          // }
                                          // setdata(res.data.data);
                                        } catch (error) {}
                                      }}
                                      // InputProps={{
                                      //   ...params.InputProps,
                                      //   endAdornment: (
                                      //     <React.Fragment>
                                      //       {!editar ? (
                                      //         <IconButton
                                      //           size="small"
                                      //           aria-label=""
                                      //           onClick={async () => {
                                      //             seteditarProveedor(false);
                                      //             setopenProveedor(true);
                                      //           }}
                                      //         >
                                      //           <AddCircleOutlineTwoTone color="primary"  fontSize="small"/>
                                      //         </secondary>
                                      //       ) : null}
                                      //       {params.InputProps.endAdornment}
                                      //     </React.Fragment>
                                      //   ),
                                      // }}
                                    />
                                  )}
                                />
                              </FormControl>
                              <FormControl fullWidth className={clases.formD}>
                                <Autocomplete
                                  options={sexoData.map((item) => {
                                    return item.sexNombre;
                                  })}
                                  getOptionLabel={(option) => {
                                    return option.replace("/+/", " ");
                                  }}
                                  id="controlled-demo"
                                  value={mascSexo}
                                  paperprops
                                  onChange={async (event, newValue) => {
                                    if (newValue) {
                                      setitem({
                                        ...item,
                                        mascSexo: newValue,
                                      });
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size="small"
                                      error={error.mascSexo}
                                      label="Sexo"
                                      fullWidth
                                      onChange={async (e) => {
                                        if (e.target.value === "") {
                                          return;
                                        }
                                        try {
                                          // const res = await clienteAxios.get();
                                          // if (!res.data.data) {
                                          //   return mostrarAlerta(res.data.msg, "error");
                                          // }
                                          // setdata(res.data.data);
                                        } catch (error) {}
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {!editar ? (
                                              <IconButton
                                                size="small"
                                                aria-label=""
                                                onClick={async () => {
                                                  setopenSexo(true);
                                                }}
                                              >
                                                <AddCircleOutlineTwoTone
                                                  color="secondary"
                                                  fontSize="small"
                                                />
                                              </IconButton>
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                              <TextField
                                type="number"
                                className={clases.formD}
                                label="Peso"
                                value={mascPeso}
                                name="mascPeso"
                                error={error.mascPeso}
                                onChange={(e) => handleChange(e)}
                              />
                              <TextField
                                className={clases.formD}
                                label="Color"
                                value={mascColor}
                                name="mascColor"
                                error={error.mascColor}
                                onChange={(e) => handleChange(e)}
                              />
                              <>
                                <TextField
                                  className={clases.formD}
                                  label="Estado"
                                  value={mascEstado}
                                  name="mascEstado"
                                  error={error.mascEstado}
                                  onChange={(e) => handleChange(e)}
                                  select
                                  InputLabelProps={{ shrink: true }}
                                  SelectProps={{ displayEmpty: true }}
                                >
                                  <MenuItem value="">Seleccione.</MenuItem>
                                  {["Vivo", "Fallecido", "Extraviado"].map(
                                    (item) => {
                                      return (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      );
                                    }
                                  )}
                                </TextField>
                                <TextField
                                  InputLabelProps={{ shrink: true }}
                                  type="date"
                                  className={clases.formD}
                                  label="F. Nacimiento"
                                  value={mascFechaNacimiento}
                                  inputProps={{
                                    className: clases.inputPadding,
                                    max: minFecha,
                                  }}
                                  name="mascFechaNacimiento"
                                  error={error.mascFechaNacimiento}
                                  onChange={(e) => {
                                    if (e.target.value !== "") {
                                      const fechaE = Date.parse(e.target.value);
                                      const fechaA = Date.parse(
                                        new Date()
                                          .toISOString()
                                          .substring(0, 10)
                                      );
                                      const fechaDiff = fechaA - fechaE;

                                      let diasDiff = fechaDiff / 86400000;

                                      let años = 0;
                                      let booleanAño = false;
                                      const actualBisiesto =
                                        new Date().getFullYear() % 4 === 0
                                          ? 366
                                          : 365;
                                      while (booleanAño === false) {
                                        if (diasDiff < actualBisiesto) {
                                          booleanAño = true;
                                        } else {
                                          const res = Math.trunc(
                                            diasDiff / actualBisiesto
                                          );

                                          for (
                                            let index = 1;
                                            index <= res;
                                            index++
                                          ) {
                                            let año = new Date(
                                              new Date().setYear(
                                                new Date().getFullYear() - index
                                              )
                                            ).getFullYear();
                                            var resto = año % 4;
                                            if (resto === 0) {
                                              diasDiff = diasDiff - 366;
                                            } else {
                                              diasDiff = diasDiff - 365;
                                            }
                                          }

                                          años = res;
                                          booleanAño = true;
                                        }
                                      }

                                      // diasDiff = diasDiff === -1 ? 0 : 0;

                                      let meses = 0;
                                      let booleanMes = false;
                                      let diasDelMesActual = new Date(
                                        new Date().getFullYear() - años,
                                        new Date().getMonth(),
                                        0
                                      ).getDate();

                                      while (booleanMes === false) {
                                        if (diasDiff < diasDelMesActual) {
                                          booleanMes = true;
                                        } else {
                                          const res = Math.trunc(
                                            diasDiff / diasDelMesActual
                                          );

                                          for (
                                            let index = 1;
                                            index <= res;
                                            index++
                                          ) {
                                            // let mes = new Date(
                                            //   new Date().setMonth(
                                            //     new Date().getMonth() - index
                                            //   )
                                            // ).getMonth();

                                            diasDiff =
                                              diasDiff - diasDelMesActual;
                                          }

                                          meses = res;
                                          booleanMes = true;
                                        }
                                      }

                                      setdias(diasDiff);
                                      setmeses(meses);
                                      setaños(años);
                                      setitem({
                                        ...item,
                                        mascFechaNacimiento: e.target.value,
                                      });
                                    }
                                  }}
                                />
                                <TextField
                                  type="number"
                                  className={clases.formD}
                                  label="Años"
                                  value={años}
                                  onChange={(e) => {
                                    if (
                                      e.target.valueAsNumber > 26 ||
                                      e.target.valueAsNumber < 0
                                    ) {
                                      return;
                                    }
                                    const diasres =
                                      Number(dias) === 0
                                        ? 1
                                        : Number(dias) * unDia;
                                    const mesesres =
                                      Number(meses) === 0
                                        ? 1
                                        : Number(meses) * unDia * 30;
                                    const añosres =
                                      Number(e.target.valueAsNumber) === 0
                                        ? 1
                                        : Number(e.target.valueAsNumber) *
                                          unDia *
                                          365;

                                    const res = new Date(
                                      Date.parse(
                                        new Date()
                                          .toISOString()
                                          .substring(0, 10)
                                      ) -
                                        (new Date().getTimezoneOffset() *
                                          60 *
                                          1000 +
                                          diasres +
                                          mesesres +
                                          añosres)
                                    )
                                      .toISOString()
                                      .substring(0, 10);

                                    setitem({
                                      ...item,
                                      mascFechaNacimiento: res,
                                    });
                                    setaños(e.target.valueAsNumber);
                                  }}
                                />
                                <TextField
                                  type="number"
                                  className={clases.formD}
                                  label="Meses"
                                  value={meses}
                                  onChange={(e) => {
                                    if (
                                      e.target.valueAsNumber > 11 ||
                                      e.target.valueAsNumber < 0
                                    ) {
                                      return;
                                    }
                                    const diasres =
                                      Number(dias) === 0
                                        ? 1
                                        : Number(dias) * unDia;
                                    const mesesres =
                                      Number(e.target.valueAsNumber) === 0
                                        ? 1
                                        : Number(e.target.valueAsNumber) *
                                          unDia *
                                          30;
                                    const añosres =
                                      Number(años) === 0
                                        ? 1
                                        : Number(años) * unDia * 365;

                                    const res = new Date(
                                      Date.parse(
                                        new Date()
                                          .toISOString()
                                          .substring(0, 10)
                                      ) -
                                        (new Date().getTimezoneOffset() *
                                          60 *
                                          1000 +
                                          diasres +
                                          mesesres +
                                          añosres)
                                    )
                                      .toISOString()
                                      .substring(0, 10);

                                    setitem({
                                      ...item,
                                      mascFechaNacimiento: res,
                                    });
                                    setmeses(e.target.valueAsNumber);
                                  }}
                                />
                                <TextField
                                  type="number"
                                  className={clases.formD}
                                  label="Dias"
                                  value={dias}
                                  onChange={(e) => {
                                    if (
                                      e.target.valueAsNumber > 31 ||
                                      e.target.valueAsNumber < 0
                                    ) {
                                      return;
                                    }
                                    const diasres =
                                      Number(e.target.valueAsNumber) === 0
                                        ? 1
                                        : Number(e.target.valueAsNumber) *
                                          unDia;
                                    const mesesres =
                                      Number(meses) === 0
                                        ? 1
                                        : Number(meses) * unDia * 30;
                                    const añosres =
                                      Number(años) === 0
                                        ? 1
                                        : Number(años) * unDia * 365;

                                    const res = new Date(
                                      Date.parse(
                                        new Date()
                                          .toISOString()
                                          .substring(0, 10)
                                      ) -
                                        (new Date().getTimezoneOffset() *
                                          60 *
                                          1000 +
                                          diasres +
                                          mesesres +
                                          añosres)
                                    )
                                      .toISOString()
                                      .substring(0, 10);

                                    setitem({
                                      ...item,
                                      mascFechaNacimiento: res,
                                    });

                                    setdias(e.target.valueAsNumber);
                                  }}
                                />
                                {/* <TextField
                        disabled
                        className={clases.formD}
                        label="Edad"
                        value={mascEdad}
                        name="mascEdad"
                        error={error.mascEdad}
                        onChange={(e) => handleChange(e)}
                      /> */}
                              </>
                            </>
                          ) : null}
                          {timeline && <TimelineComponent item={item} />}
                          {contactoExtraSeccion ? (
                            <>
                              <FormControl className={clases.formAllW}>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent={"space-between"}
                                >
                                  <Typography
                                    variant="subtitle1"
                                    color={
                                      error.mascContactoExtra
                                        ? "error"
                                        : "primary"
                                    }
                                  >
                                    Dato:
                                    <IconButton
                                      aria-label=""
                                      size="small"
                                      onClick={async () => {
                                        const existeVacio = item.mascContactoExtra.some(
                                          (item) => {
                                            return (
                                              item.nombre === "" ||
                                              item.celular === "" ||
                                              item.correo === ""
                                            );
                                          }
                                        );

                                        if (existeVacio) {
                                          return mostrarAlerta(
                                            "Complete todos los campos.",
                                            "error"
                                          );
                                        }
                                        setitem({
                                          ...item,
                                          mascContactoExtra: [
                                            ...mascContactoExtra,
                                            {
                                              nombre: "",
                                              celular: "",
                                              correo: "",
                                            },
                                          ],
                                        });
                                      }}
                                    >
                                      <AddCircle
                                        fontSize="small"
                                        color="secondary"
                                      />
                                    </IconButton>
                                  </Typography>
                                </Box>
                              </FormControl>

                              {mascContactoExtra
                                .sort((a, b) => a.orden - b.orden)
                                .map((dato, index) => {
                                  return (
                                    <>
                                      <Box
                                        display={"flex"}
                                        justifyContent="space-between"
                                        className={clases.formAllW}
                                        key={index}
                                      >
                                        <Typography
                                          key={index}
                                          variant="button"
                                          color="primary"
                                          fontWeight={"700"}
                                        >
                                          {`Dato #${index + 1}`}
                                          <IconButton
                                            size="small"
                                            onClick={() => {
                                              setitem({
                                                ...item,
                                                mascContactoExtra: mascContactoExtra.filter(
                                                  (item, indexItem) => {
                                                    return indexItem !== index;
                                                  }
                                                ),
                                              });
                                            }}
                                          >
                                            <Delete
                                              color="error"
                                              fontSize="small"
                                            />
                                          </IconButton>
                                        </Typography>
                                      </Box>
                                      {/* DATO */}

                                      <>
                                        <TextField
                                          className={clases.form30PC}
                                          size="small"
                                          label="Nombre"
                                          value={dato.nombre}
                                          onChange={(e) => {
                                            setitem({
                                              ...item,
                                              mascContactoExtra: mascContactoExtra.map(
                                                (item, indexItem) => {
                                                  if (indexItem === index) {
                                                    return {
                                                      ...item,
                                                      nombre: e.target.value,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                            });
                                          }}
                                        />
                                        <TextField
                                          className={clases.form30PC}
                                          size="small"
                                          label="Celular"
                                          value={dato.celular}
                                          onChange={(e) => {
                                            setitem({
                                              ...item,
                                              mascContactoExtra: mascContactoExtra.map(
                                                (item, indexItem) => {
                                                  if (indexItem === index) {
                                                    return {
                                                      ...item,
                                                      celular: e.target.value,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                            });
                                          }}
                                        />
                                        <TextField
                                          className={clases.form30PC}
                                          size="small"
                                          label="Correo"
                                          value={dato.correo}
                                          onChange={(e) => {
                                            setitem({
                                              ...item,
                                              mascContactoExtra: mascContactoExtra.map(
                                                (item, indexItem) => {
                                                  if (indexItem === index) {
                                                    return {
                                                      ...item,
                                                      correo: e.target.value,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                            });
                                          }}
                                        />
                                      </>

                                      {/* DELETE */}
                                    </>
                                  );
                                })}
                            </>
                          ) : null}
                          {observacionSeccion ? (
                            <>
                              <FormControl fullWidth className={clases.formD}>
                                <Autocomplete
                                  filterOptions={(x) => x}
                                  options={veterinariosData.map((item) => {
                                    return item.perNombre + "/+/" + item.perId;
                                  })}
                                  getOptionLabel={(option) => {
                                    return option.split("/+/")[0];
                                  }}
                                  id="controlled-demo"
                                  value={nombre_medico_tratante}
                                  paperprops
                                  onChange={async (event, newValue) => {
                                    if (newValue) {
                                      const options = newValue.split("/+/");
                                      const itemF = veterinariosData.find(
                                        (itemPersona) => {
                                          return (
                                            itemPersona.perId ===
                                            Number(options[1])
                                          );
                                        }
                                      );

                                      setitem({
                                        ...item,
                                        nombre_medico_tratante: itemF.perNombre,
                                        mascIdMedicoTratante: itemF.perId,
                                      });
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size="small"
                                      error={error.nombre_medico_tratante}
                                      label="Veterinario"
                                      fullWidth
                                      onChange={async (e) => {
                                        if (e.target.value === "") {
                                          return;
                                        }
                                        try {
                                          const res = await clienteAxios.get(
                                            `/proveedores/filtro/0?search=${e.target.value}&estado=VETERINARIO`
                                          );
                                          if (!res.data.data) {
                                            return mostrarAlerta(
                                              res.data.msg,
                                              "error"
                                            );
                                          }
                                          setveterinariosData(res.data.data);
                                        } catch (error) {}
                                      }}
                                      // InputProps={{
                                      //   ...params.InputProps,
                                      //   endAdornment: (
                                      //     <React.Fragment>
                                      //       {!editar ? (
                                      //         <IconButton
                                      //           size="small"
                                      //           aria-label=""
                                      //           onClick={async () => {
                                      //             seteditarProveedor(false);
                                      //             setopenProveedor(true);
                                      //           }}
                                      //         >
                                      //           <AddCircleOutlineTwoTone color="primary" />
                                      //         </IconButton>
                                      //       ) : null}
                                      //       {params.InputProps.endAdornment}
                                      //     </React.Fragment>
                                      //   ),
                                      // }}
                                    />
                                  )}
                                />
                              </FormControl>
                              <TextField
                                className={clases.formD}
                                label="MicroChip"
                                value={mascMicroChip}
                                name="mascMicroChip"
                                error={error.mascMicroChip}
                                onChange={(e) => handleChange(e)}
                              />
                              <TextField
                                className={clases.formD}
                                label="Caracter"
                                value={mascCaracter}
                                name="mascCaracter"
                                error={error.mascCaracter}
                                onChange={(e) => handleChange(e)}
                              />
                              <TextField
                                multiline
                                rows={3}
                                variant="outlined"
                                className={clases.formAllW}
                                label="Observación"
                                value={mascObservacion}
                                name="mascObservacion"
                                error={error.mascObservacion}
                                onChange={(e) => handleChange(e)}
                              />
                            </>
                          ) : null}
                        </>
                      </Box>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          className={clases.formD}
                          color="secondary"
                          fontSize={".9rem"}
                          fontWeight="bold"
                        >
                          Crea:
                          <Typography
                            className={clases.formD}
                            color="primary"
                            fontSize={".9rem"}
                            component="span"
                          >
                            {item.mascUser.split(" ** ")[0]}
                          </Typography>
                        </Typography>
                        <Typography
                          className={clases.formD}
                          color="secondary"
                          fontSize={".9rem"}
                          fontWeight="bold"
                        >
                          Act:
                          <Typography
                            className={clases.formD}
                            color="primary"
                            fontSize={".9rem"}
                            component="span"
                          >
                            {item.mascUser.split(" ** ")[1]}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                  </>
                </Grid>
              </Grid>
              {/* <Divider /> */}
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalData);

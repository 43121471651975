import React, { useState, useContext, useEffect, memo, useRef } from "react";
import {
  Modal,
  Box,
  TextField,
  Divider,
  Typography,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TableRow,
  TableCell,
  Autocomplete,
  InputAdornment,
  Tooltip,
} from "@mui/material";

import Draggable from "react-draggable";

import {
  AddCircle,
  AddCircleOutlineTwoTone,
  Cancel,
  Create,
  Delete,
  Save,
} from "@mui/icons-material";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";

import AuthContext from "../../../../Context/Auth/AuthContext";

import RowProducto from "./Productos/RowProducto";
import RowAgregarProducto from "./Productos/RowAgregarProducto";

import clienteAxios from "../../../../config/axios";

import { MonetizationOn } from "@mui/icons-material";
import { replaceCaracteres, trimPropsItem } from "../../../../config/const";
import { validarEmail } from "../../../../config/validaciones";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import TablaCabecera from "../../../../components/Extra/TablaCabecera";
import ToolBarTable from "../../../../components/Extra/ToolBarTable";
import { usePermiso } from "../../../../hooks/usePermiso";
import { useDraggable } from "../../../../hooks/useDraggable";
import { calcularProducto } from "./helpers";
import { calcularTotales } from "./helpers/calcularTotales";
import { useModalStyle } from "../../../../styles/styleModal";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "90vh",
  };
}

const ModalAsistencia = (props) => {
  const { alertaPermiso, tienePermiso } = usePermiso("Facturacion");
  const [dinero, setdinero] = useState("0");
  const inputRef = useRef();
  const inputRef2 = useRef();
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);

  const { usuario } = useContext(AuthContext);
  const [codigoBarra, setcodigoBarra] = useState("");
  const [cantidadGasolinaDinero, setcantidadGasolinaDinero] = useState(0);
  const [focusCantidad, setfocusCantidad] = useState(false);
  const [otrosData, setotrosData] = useState([]);
  const [subcategoriasData, setsubcategoriasData] = useState(null);

  const [producto, setproducto] = useState({
    invSerie: "",
    invTipo: "",
    invNombre: "",
    invDescripcion: "",
    invCosto1: "",
    invCosto2: "",
    invCosto3: "",
    invStock: "",
    invIva: "",
    invIncluyeIva: "",
    invEstado: "",
    invBodegas: [],
    invprecios: [],
    invProveedor: "",
    invCategoria: "",
    invSubCategoria: "",
    invUnidad: "",
    invMarca: "",
    invSubsidio: "",
    invPlanCuenta: "",
    invEmpresa: "",
    invUser: "",
    invFecReg: "",
  });
  const [itemRow, setItemRow] = useState({
    stock: 0,
    subcategoria: "",
    limiteStock: false,
    cantidad: "1",
    codigo: "",
    descripcion: "",
    valUnitarioInterno: "",
    valorUnitario: "",
    recargoPorcentaje: "",
    recargo: "",
    descPorcentaje: "",
    descuento: "",
    precioSubTotalProducto: "",
    valorIva: "",
    costoProduccion: "",
    llevaIva: "SI",
    incluyeIva: "NO",
  });

  //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    setopenInventario,
    setOpenProveedor,
    estadoRequest,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    desdePeluqueria,
    seteditarInventario,
    changeObjetoActivoStateInventario,
    seteditarProveedor,
    changeObjetoActivoStateProveedor,
  } = props;
  const [item, setitem] = useState(ObjectoActivo);
  const [agregarProducto, setagregarProducto] = useState(false);
  const [cargando, setcargando] = useState(false);
  const [openConfirmacionDialog, setopenConfirmacionDialog] = useState(false);
  const [clientesData, setclientesData] = useState([]);
  const [formasDePago, setformaDePago] = useState(null);

  // eslint-disable-next-line

  const {
    // venTipoDocumento,
    venIdCliente,
    venRucCliente,
    // venTipoDocuCliente,
    venNomCliente,
    // venEmailCliente,
    // venTelfCliente,
    // venCeluCliente,
    // venDirCliente,
    // venEmpRuc,
    // venEmpNombre,
    // venEmpComercial,
    // venEmpDireccion,
    // venEmpTelefono,
    // venEmpEmail,
    // venEmpObligado,
    venFormaPago,
    venNumero,
    venFacturaCredito,
    venDias,
    venAbono,
    venDescPorcentaje,
    venOtrosDetalles,
    venObservacion,
    venSubTotal12,
    venSubtotal0,
    venDescuento,
    venSubTotal,
    venTotalIva,
    venTotal,
    // venCostoProduccion,
    // venUser,
    venFechaFactura,
    venNumFactura,
    // venNumFacturaAnterior,
    // venAutorizacion,
    // venFechaAutorizacion,
    // venErrorAutorizacion,
    venEstado,
    // venEnvio,
    // fechaSustentoFactura,
    // venEmpresa,
    venProductos,
    venEmailCliente,
    venKilometrajeInicio,
    venKilometrajeFinal,
  } = item;
  const [error, seterror] = useState({
    venIdCliente: false,
    venRucCliente: false,
    venNomCliente: false,
    venEmailCliente: false,
  });
  const [secuencia, setsecuencia] = useState("");

  useEffect(() => {
    const getDataformasDePago = async () => {
      if (!formasDePago) {
        const res = await clienteAxios.get("/formaPagos/filtro/0");

        setformaDePago(res.data.data);
      }
    };
    getDataformasDePago();
    const getDatasubcategoriasData = async () => {
      if (
        !subcategoriasData &&
        (desdePeluqueria || item.venObservacion.includes("DESDEPELUQUERIA"))
      ) {
        const res = await clienteAxios.get(
          `/subcategorias/filtro/0?empresa=${
            usuario.rucempresa
          }&categoria=${"PELUQUERIA"}`
        );

        setsubcategoriasData(res.data.data);
      }
    };
    getDatasubcategoriasData();

    const getDataState = async () => {
      try {
        const res = await clienteAxios.get(
          `/secuencias/filtro/0?empresa=${usuario.rucempresa}&search=${
            estadoRequest === "FACTURAS"
              ? "FACTURA"
              : estadoRequest === "NOTA VENTAS"
              ? "NOTA DE VENTA"
              : estadoRequest === "PROFORMAS"
              ? "PROFORMA"
              : estadoRequest === "NOTA CREDITOS"
              ? "NOTA DE CREDITO"
              : ObjectoActivo.venTipoDocumento === "F"
              ? "FACTURA"
              : ObjectoActivo.venTipoDocumento === "NC"
              ? "NOTA DE CREDITO"
              : ""
          }`
        );
        if (!res.data.secuencia && estadoRequest !== "ANULADAS" && open) {
          mostrarAlerta("CONFIGURE EL NÚMERO DE SECUENCIA", "error");
          return setopen(false);
        }

        setsecuencia(res.data.secuencia);
      } catch (error) {}
    };
    getDataState();

    setotrosData(
      ObjectoActivo.venOtrosDetalles ? [ObjectoActivo.venOtrosDetalles] : []
    );
    setitem({
      ...ObjectoActivo,
      venFormaPago: ObjectoActivo.venId
        ? ObjectoActivo.venFormaPago
        : usuario.rucempresa === "TE2021"
        ? "EFECTIVO"
        : ObjectoActivo.venFormaPago,
      venIdCliente: ObjectoActivo.venId
        ? ObjectoActivo.venIdCliente
        : usuario.rucempresa === "TE2021"
        ? ""
        : ObjectoActivo.venIdCliente,
      venRucCliente: ObjectoActivo.venId
        ? ObjectoActivo.venRucCliente
        : usuario.rucempresa === "TE2021"
        ? ""
        : ObjectoActivo.venRucCliente,
      venNomCliente: ObjectoActivo.venId
        ? ObjectoActivo.venNomCliente
        : usuario.rucempresa === "TE2021"
        ? ""
        : ObjectoActivo.venNomCliente,
    });
    // eslint-disable-next-line
  }, [ObjectoActivo]);

  // const [ItemOtroLugarProveedor, setItemOtroLugarProveedor] = useState({});
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    itemSocketData: {},
  });

  useEffect(() => {
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        itemSocketData: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      setitemSocket({
        tipo: "editar",
        itemSocketData: data,
      });
    });

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { itemSocketData, tipo } = itemSocket;

    if (!open) {
      return;
    }

    if (tipo === "") {
      return;
    }

    if (usuario.rucempresa !== itemSocketData.rucempresa) {
      return;
    }

    const funcionAalerta = () => {
      if (itemSocketData.msg === "") {
        return null;
      }
      if (!itemSocketData.msg) {
        return null;
      }
      mostrarAlerta(
        itemSocketData.msg,
        itemSocketData.alert ? itemSocketData.alert : "success"
      );
    };

    if (tipo === "agregar") {
      if (itemSocketData.tabla === "proveedor") {
        if (usuario.usuario === itemSocketData.perUser) {
          setitem({
            ...item,
            venRucCliente: itemSocketData.perDocNumero,
            venNomCliente: itemSocketData.perNombre,
            venIdCliente: itemSocketData.perId,
            venTipoDocuCliente: itemSocketData.perDocTipo,
            venEmailCliente: itemSocketData.perEmail,
            venTelfCliente: itemSocketData.perTelefono,
            venCeluCliente: itemSocketData.perCelular,
            venDirCliente: itemSocketData.perDireccion,
            venOtrosDetalles:
              itemSocketData.perOtros.length === 0
                ? ""
                : itemSocketData.perOtros[0],
          });
          setotrosData(itemSocketData.perOtros);
          // setItemOtroLugarProveedor(itemSocketData);
        }
      }
    }

    if (tipo === "editar") {
      if (itemSocketData.tabla === "proveedor") {
        if (
          usuario.usuario === itemSocketData.perUser &&
          itemSocketData.perId === item.venIdCliente
        ) {
          setitem({
            ...item,
            venRucCliente: itemSocketData.perDocNumero,
            venNomCliente: itemSocketData.perNombre,
            venIdCliente: itemSocketData.perId,
            venTipoDocuCliente: itemSocketData.perDocTipo,
            venEmailCliente: itemSocketData.perEmail,
            venTelfCliente: itemSocketData.perTelefono,
            venCeluCliente: itemSocketData.perCelular,
            venDirCliente: itemSocketData.perDireccion,
            venOtrosDetalles:
              itemSocketData.perOtros.length === 0
                ? ""
                : itemSocketData.perOtros[0],
          });
          setotrosData(itemSocketData.perOtros);
          // setItemOtroLugarProveedor(itemSocketData);
        }
      }
    }
    funcionAalerta();
    setitemSocket({ tipo: "", itemSocketData: {} });
    // eslint-disable-next-line
  }, [itemSocket]);
  const defaultActive = () => {
    // 1 nuevo
    // 2 copia
    // 3 editar
    // 4 nota de credito
    changeObjetoActivoState({
      venKilometrajeInicio: "",
      venKilometrajeFinal: "",
      venEmpRegimen: "",
      venProductosAntiguos: [],
      optionDocumento: "",
      venTotalRetencion: "0.00",
      venOption: "1",
      venTipoDocumento: "N",
      venIdCliente: "1",
      venRucCliente: "9999999999999",
      venTipoDocuCliente: "RUC",
      venNomCliente: "CONSUMIDOR FINAL",
      venEmailCliente: ["sin@sincorreo.com"],
      venTelfCliente: "0000000001",
      venCeluCliente: "0000000001",
      venDirCliente: "s/n",
      venEmpRuc: "",
      venEmpNombre: "",
      venEmpComercial: "",
      venEmpDireccion: "",
      venEmpTelefono: "",
      venEmpEmail: "",
      venEmpObligado: "",
      venFormaPago: "TRANSFERENCIA",
      venNumero: "0",
      venFacturaCredito: "NO",
      venDias: "0",
      venAbono: "0",
      venDescPorcentaje: "0",
      venOtrosDetalles: "",
      venObservacion: "",
      venSubTotal12: "",
      venSubtotal0: "",
      venDescuento: "",
      venSubTotal: "",
      venTotalIva: "",
      venTotal: "0.00",
      venCostoProduccion: "",
      venUser: "",
      venFechaFactura: `${new Date().getFullYear()}-${(
        "0" +
        (new Date().getMonth() + 1)
      ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
      venNumFactura: "",
      venNumFacturaAnterior: "",
      venAutorizacion: "0",
      venFechaAutorizacion: "",
      venErrorAutorizacion: "NO",
      venEstado: "ACTIVA",
      venEnvio: "NO",
      fechaSustentoFactura: "",
      venEmpresa: "",
      venProductos: [],
    });
    if (usuario.rucempresa !== "TE2021") {
      setopen(false);
    }
    seteditar(false);
    seterror({
      venIdCliente: false,
      venRucCliente: false,
      venNomCliente: false,
    });
    setdinero("0");
    setcargando(false);
    setagregarProducto(false);
    setotrosData([]);
  };

  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };
  const { handleDragStart, handleDragEnter, handleDragEnd } = useDraggable();
  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);
  const validacion = () => {
    if (
      item.venIdCliente === "" ||
      item.venRucCliente === "" ||
      item.venNomCliente === "" ||
      (item.venOtrosDetalles === "" && usuario.rucempresa === "TE2021") ||
      venEmailCliente.some((itemCorreo) => {
        return validarEmail(itemCorreo) || itemCorreo.trim() === "";
      }) ||
      (usuario.rucempresa === "JEFF2023" &&
        (venKilometrajeInicio === "" || venKilometrajeFinal === ""))
    ) {
      seterror({
        venIdCliente: item.venIdCliente === "" ? true : false,
        venRucCliente: item.venRucCliente === "" ? true : false,
        venNomCliente: item.venNomCliente === "" ? true : false,
        venEmailCliente: venEmailCliente.some((itemCorreo) => {
          return validarEmail(itemCorreo) || itemCorreo.trim() === "";
        }),
        venKilometrajeInicio:
          usuario.rucempresa === "JEFF2023" && venKilometrajeInicio === "",
        venKilometrajeFinal:
          usuario.rucempresa === "JEFF2023" && venKilometrajeFinal === "",
        venOtrosDetalles:
          item.venOtrosDetalles === "" && usuario.rucempresa === "TE2021",
      });
      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async (optionDocumento) => {
    if (!tienePermiso("agregar")) {
      return alertaPermiso("agregar");
    }
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios", "error");
    }
    const totalCantidad = venProductos.reduce((acumulador, producto) => {
      const cantidadNumerica = parseFloat(producto.cantidad.replace(",", "."));
      return acumulador + cantidadNumerica;
    }, 0);
    console.log(totalCantidad);
    if (
      usuario.rucempresa === "TE2021" &&
      venOtrosDetalles === "ZZZ9999" &&
      totalCantidad > 5
    ) {
      return mostrarAlerta(
        "No se puede vender mas de 5 galones a ZZZ9999",
        "error"
      );
    }
    if (
      (venRucCliente === "9999999999999" || venRucCliente === "9999999999") &&
      (venFacturaCredito === "SI" || venAbono > 0)
    ) {
      return mostrarAlerta("Ingrese datos de un cliente", "error");
    }
    if (Number(venTotal) === Number(venAbono) && venFacturaCredito === "SI") {
      return mostrarAlerta("No puede registrar como Factura/Crédito", "error");
    }
    if (venProductos.length === 0) {
      return mostrarAlerta("Ingrese al menos un producto", "error");
    }
    try {
      setcargando(true);
      console.log(
        trimPropsItem({
          ...item,
          optionDocumento,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          venUser: usuario.usuario,
          venEmpresa: usuario.rucempresa,
          tabla: "venta",
          venObservacion: venObservacion.includes("DESDEPELUQUERIA")
            ? venObservacion
            : desdePeluqueria
            ? venObservacion + "DESDEPELUQUERIA"
            : venObservacion,
        })
      );
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          optionDocumento,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          venUser: usuario.usuario,
          venEmpresa: usuario.rucempresa,
          tabla: "venta",
          venObservacion: venObservacion.includes("DESDEPELUQUERIA")
            ? venObservacion
            : desdePeluqueria
            ? venObservacion + "DESDEPELUQUERIA"
            : venObservacion,
        })
      );
      return defaultActive();
    } catch (error) {
      setcargando(false);
      return mostrarAlerta("Hubo un error", "error");
    }
  };
  const handleEditar = async (optionDocumento) => {
    if (!tienePermiso("editar")) {
      return alertaPermiso("editar");
    }
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios", "error");
    }
    if (
      (venRucCliente === "9999999999999" || venRucCliente === "9999999999") &&
      (venFacturaCredito === "SI" || venAbono > 0)
    ) {
      return mostrarAlerta("Ingrese datos de un cliente", "error");
    }
    if (Number(venTotal) === Number(venAbono) && venFacturaCredito === "SI") {
      return mostrarAlerta("No puede registrar como Factura/Crédito", "error");
    }
    if (venProductos.length === 0) {
      return mostrarAlerta("Ingrese al menos un producto", "error");
    }
    try {
      setcargando(true);
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          optionDocumento,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          venUser: usuario.usuario,
          venEmpresa: usuario.rucempresa,
          tabla: "venta",
          venObservacion: venObservacion.includes("DESDEPELUQUERIA")
            ? venObservacion
            : desdePeluqueria
            ? venObservacion + "DESDEPELUQUERIA"
            : venObservacion,
        })
      );
      return defaultActive();
    } catch (error) {
      setcargando(false);
      return mostrarAlerta("Hubo un error", "error");
    }
  };
  //filas por pagina

  // eslint-disable-next-line
  const funcion1 = () => {
    if (editar) {
      handleEditar("F");
    } else {
      handleCrear("F");
    }
  };
  const funcion2 = () => {
    if (editar) {
      handleEditar("N");
    } else {
      handleCrear("N");
    }
  };
  const funcion3 = () => {
    if (editar) {
      handleEditar("P");
    } else {
      handleCrear("P");
    }
  };
  const funcion4 = () => {
    if (editar) {
      handleEditar("NC");
    } else {
      handleCrear("NC");
    }
  };
  // 1 + 2 + 3 + 4

  if (!formasDePago) {
    return null;
  }
  if (!open) {
    return null;
  }
  if (
    !subcategoriasData &&
    (desdePeluqueria || item.venObservacion.includes("DESDEPELUQUERIA"))
  ) {
    return null;
  }
  return (
    <>
      <ConfirmacionDialog
        open={openConfirmacionDialog}
        setopen={setopenConfirmacionDialog}
        titulo={
          // editar && ObjectoActivo.venTipoDocumento === "N"
          //   ? "¿DESEA CONVERTIR A FACTURA O CONSERVAR COMO NOTA DE VENTA?"
          //   : ObjectoActivo.venTipoDocumento === "NC"
          //   ? "¿ESTA SEGURO DE HACER NOTA DE CRÉDITO?"
          //   : ObjectoActivo.venTipoDocumento === "P"
          //   ? "¿DESEA CONVERTIR A FACTURA O CONSERVAR COMO PROFORMA?"
          //   : "¿DESEA FACTURAR, HACER NOTA DE VENTA O PROFORMA?"
          editar &&
          ObjectoActivo.venEstado !== item.venEstado &&
          item.venEstado === "ANULADA" &&
          (item.venTipoDocumento === "F" ||
            item.venTipoDocumento === "NC" ||
            item.venTipoDocumento === "N" ||
            item.venTipoDocumento === "P")
            ? `¿DESEA ANULAR ESTA ${
                item.venTipoDocumento === "F"
                  ? "FACTURA"
                  : item.venTipoDocumento === "NC"
                  ? "NOTA DE CRÉDITO"
                  : item.venTipoDocumento === "N"
                  ? "PREFACTURA"
                  : "PROFORMA"
              }? `
            : editar && ObjectoActivo.venTipoDocumento === "N"
            ? "¿DESEA CONVERTIR A FACTURA O CONSERVAR COMO PREFACTURA?"
            : editar && ObjectoActivo.venTipoDocumento === "P"
            ? "¿DESEA CONVERTIR A FACTURA, PREFACTURA O CONSERVAR COMO PROFORMA?"
            : ObjectoActivo.venTipoDocumento === "NC"
            ? "¿ESTA SEGURO DE HACER NOTA DE CRÉDITO?"
            : (item.venTipoDocumento === "F" ||
                item.venTipoDocumento === "NC") &&
              item.venEstado === "GENERAR XML"
            ? "¿DESEA GENERAR XML?"
            : (item.venTipoDocumento === "F" ||
                item.venTipoDocumento === "NC") &&
              item.venEstado === "SIN AUTORIZAR"
            ? "¿DESEA AUTORIZAR ESTE DOCUMENTO?"
            : (item.venTipoDocumento === "F" ||
                item.venTipoDocumento === "NC") &&
              item.venEstado === "AUTORIZADO"
            ? "¿ESTE DOCUMENTO YA SE ENCUENTRA AUTORIZADO?"
            : "¿DESEA FACTURAR, HACER PREFACTURA O PROFORMA?"
        }
        funcion1={funcion1}
        funcion2={funcion2}
        funcion3={funcion3}
        funcion4={funcion4}
        texto1={
          (editar &&
            ObjectoActivo.venEstado !== item.venEstado &&
            item.venEstado === "ANULADA" &&
            item.venTipoDocumento === "F") ||
          item.venEstado === "GENERAR XML" ||
          item.venEstado === "AUTORIZADO" ||
          item.venEstado === "SIN AUTORIZAR"
            ? "SI"
            : "FACTURA"
        }
        texto2={
          editar &&
          ObjectoActivo.venEstado !== item.venEstado &&
          item.venEstado === "ANULADA" &&
          item.venTipoDocumento === "N"
            ? "SI"
            : "PREFACTURA"
        }
        texto3={
          editar &&
          ObjectoActivo.venEstado !== item.venEstado &&
          item.venEstado === "ANULADA" &&
          item.venTipoDocumento === "P"
            ? "SI"
            : "PROFORMA"
        }
        texto4={
          (editar &&
            ObjectoActivo.venEstado !== item.venEstado &&
            item.venEstado === "ANULADA" &&
            item.venTipoDocumento === "NC") ||
          item.venEstado === "GENERAR XML" ||
          item.venEstado === "AUTORIZADO" ||
          item.venEstado === "SIN AUTORIZAR"
            ? "SI"
            : "NOTA CRÉDITO"
        }
        deshabilitarBoton1={
          editar &&
          ObjectoActivo.venEstado !== item.venEstado &&
          item.venEstado === "ANULADA" &&
          (item.venTipoDocumento === "P" || item.venTipoDocumento === "N")
        }
        deshabilitarBoton2={
          (editar &&
            ObjectoActivo.venEstado !== item.venEstado &&
            item.venEstado === "ANULADA" &&
            (item.venTipoDocumento === "P" || item.venTipoDocumento === "F")) ||
          ((item.venTipoDocumento === "F" || item.venTipoDocumento === "NC") &&
            (item.venEstado === "GENERAR XML" ||
              item.venEstado === "AUTORIZADO" ||
              item.venEstado === "SIN AUTORIZAR"))
        }
        deshabilitarBoton3={
          (editar &&
            ObjectoActivo.venEstado !== item.venEstado &&
            item.venEstado === "ANULADA" &&
            (item.venTipoDocumento === "N" || item.venTipoDocumento === "F")) ||
          (editar && item.venTipoDocumento === "N") ||
          ((item.venTipoDocumento === "F" || item.venTipoDocumento === "NC") &&
            (item.venEstado === "GENERAR XML" ||
              item.venEstado === "AUTORIZADO" ||
              item.venEstado === "SIN AUTORIZAR"))
        }
        deshabilitarBoton4={false}
        NotaCredito={ObjectoActivo.venTipoDocumento === "NC"}
        tripleOpcion={true}
        categoria={"success"}
      />

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onKeyDown={(a) => {
          if (
            cargando ||
            (editar &&
              ObjectoActivo.venEstado === "AUTORIZADO" &&
              item.venEstado !== "ANULADA") ||
            (ObjectoActivo.venEstado === "ANULADA" &&
              item.venEstado === "ANULADA")
          ) {
            return;
          }

          if (a.code === "F8") {
            setopenConfirmacionDialog(true);
          }
        }}
      >
        <Box>
          <Draggable handle="#handle">
            <Box display="flex" justifyContent="center">
              <div style={modalStyle} className={clases.paper}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  style={{ cursor: "-webkit-grab" }}
                >
                  <Typography
                    id="handle"
                    variant="button"
                    color="initial"
                    align="center"
                  >
                    {editar ? "Editar " : "Nueva "}
                    {ObjectoActivo.venTipoDocumento === "F"
                      ? "Factura"
                      : ObjectoActivo.venTipoDocumento === "N"
                      ? "Prefactura"
                      : ObjectoActivo.venTipoDocumento === "NC"
                      ? "Nota Crédito"
                      : "Proforma"}
                    <Typography
                      variant="subtitle1"
                      color="secondary"
                      component={"span"}
                    >
                      {editar ? ` - #${venNumFactura}.` : ` - #${secuencia}.`}
                    </Typography>
                  </Typography>
                  <Box display="flex" alignItems="center">
                    {editar ? (
                      <TextField
                        inputProps={{ className: clases.inputPadding }}
                        className={clases.formD25}
                        id="venEstado"
                        label="Estado"
                        value={venEstado}
                        name="venEstado"
                        error={error.venEstado}
                        onChange={(e) => handleChange(e)}
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                      >
                        <MenuItem value="">Seleccione</MenuItem>
                        {editar
                          ? (ObjectoActivo.venTipoDocumento === "NC" ||
                              ObjectoActivo.venTipoDocumento === "F") &&
                            ObjectoActivo.venEstado === "AUTORIZADO"
                            ? ["AUTORIZADO", "ANULADA"].map((item) => {
                                return (
                                  <MenuItem value={item}>{item} </MenuItem>
                                );
                              })
                            : (ObjectoActivo.venTipoDocumento === "P" ||
                                ObjectoActivo.venTipoDocumento === "N") &&
                              ObjectoActivo.venEstado === "ACTIVA"
                            ? ["ACTIVA", "ANULADA"].map((item) => {
                                return (
                                  <MenuItem value={item}>{item} </MenuItem>
                                );
                              })
                            : (ObjectoActivo.venTipoDocumento === "NC" ||
                                ObjectoActivo.venTipoDocumento === "F" ||
                                ObjectoActivo.venTipoDocumento === "P" ||
                                ObjectoActivo.venTipoDocumento === "N") &&
                              ObjectoActivo.venEstado === "ANULADA"
                            ? ["ANULADA"].map((item) => {
                                return (
                                  <MenuItem value={item}>{item} </MenuItem>
                                );
                              })
                            : ObjectoActivo.venTipoDocumento === "N" ||
                              ObjectoActivo.venTipoDocumento === "P"
                            ? ["ANULADA", "ACTIVA"].map((item) => {
                                return (
                                  <MenuItem value={item}>{item} </MenuItem>
                                );
                              })
                            : ObjectoActivo.venTipoDocumento === "NC" ||
                              ObjectoActivo.venTipoDocumento === "F"
                            ? ["SIN AUTORIZAR", "GENERAR XML", "ANULADA"].map(
                                (item) => {
                                  return (
                                    <MenuItem value={item}>{item} </MenuItem>
                                  );
                                }
                              )
                            : [
                                "AUTORIZADO",
                                "SIN AUTORIZAR",
                                "GENERAR XML",
                                "ANULADA",
                                "ACTIVA",
                              ].map((item) => {
                                return (
                                  <MenuItem value={item}>{item} </MenuItem>
                                );
                              })
                          : ["GENERAR XML", "AUTORIZADO", "ACTIVA"].map(
                              (item) => {
                                return (
                                  <MenuItem value={item}>{item} </MenuItem>
                                );
                              }
                            )}
                      </TextField>
                    ) : null}
                    <Tooltip title="Presione F8">
                      <IconButton
                        aria-label=""
                        color="primary"
                        disabled={
                          cargando ||
                          (editar &&
                            ObjectoActivo.venEstado === "AUTORIZADO" &&
                            item.venEstado !== "ANULADA") ||
                          (ObjectoActivo.venEstado === "ANULADA" &&
                            item.venEstado === "ANULADA")
                        }
                        onClick={() => {
                          if (editar) {
                            setopenConfirmacionDialog(true);
                          } else {
                            setopenConfirmacionDialog(true);
                          }
                        }}
                      >
                        <Save />
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      aria-label=""
                      style={{ margin: "0px" }}
                      onClick={() => {
                        defaultActive();
                        setopen(false);
                      }}
                    >
                      <Cancel color="secondary" />
                    </IconButton>
                  </Box>
                </Box>
                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems=" stretch"
                  width="100%"
                >
                  <Box></Box>
                </Box>

                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="flex-end"
                  borderRadius="0px 50px 50px 0px"
                  p={1}
                >
                  {/* <Box
                className={clases.formAllW}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Divider style={{ width: "42%" }} />
                <Typography
                  style={{ width: "100%" }}
                  variant="subtitle1"
                  color="primary"
                  align="center"
                  component={"span"}
                >
                  CLIENTE / PAGO
                </Typography>
                <Divider style={{ width: "42%" }} />
              </Box> */}
                  <>
                    <FormControl fullWidth className={clases.formD25}>
                      <Autocomplete
                        options={clientesData.map((item) => {
                          return usuario.rucempresa === "TE2021"
                            ? item.perDocNumero +
                                "/+/" +
                                item.perNombre +
                                "/+/" +
                                item.perOtros.join(", ") +
                                "/+/" +
                                item.perId
                            : item.perDocNumero +
                                "/+/" +
                                item.perNombre +
                                "/+/" +
                                item.perId;
                        })}
                        getOptionLabel={(option) => {
                          return usuario.rucempresa === "TE2021"
                            ? option
                                .replace("/+/", " ")
                                .replace("/+/", ": ")
                                .split("/+/")[0]
                            : option.replace("/+/", " ").split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={(venRucCliente + " " + venIdCliente).trim()}
                        paperprops
                        autoFocus
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            const itemF =
                              usuario.rucempresa === "TE2021"
                                ? clientesData.find((itemF) => {
                                    return itemF.perId === Number(options[3]);
                                  })
                                : clientesData.find((itemF) => {
                                    return itemF.perId === Number(options[2]);
                                  });
                            inputRef.current.select();
                            setotrosData(itemF.perOtros);
                            setitem({
                              ...item,
                              venRucCliente: itemF.perDocNumero,
                              venNomCliente: itemF.perNombre,
                              venIdCliente: itemF.perId,
                              venTipoDocuCliente: itemF.perDocTipo,
                              venEmailCliente: itemF.perEmail,
                              venTelfCliente: itemF.perTelefono,
                              venCeluCliente: itemF.perCelular,
                              venDirCliente: itemF.perDireccion,
                              venOtrosDetalles:
                                itemF.perOtros.length === 0
                                  ? ""
                                  : itemF.perOtros[0],
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            label="Ruc Cliente"
                            fullWidth
                            autoFocus
                            error={error.venRucCliente}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta(
                                  "Ingrese un Nombre o Documento",
                                  "error"
                                );
                              }
                              try {
                                if (e.target.value.length < 3) {
                                  return;
                                }
                                const res = await clienteAxios.get(
                                  `${
                                    usuario.rucempresa === "TE2021"
                                      ? "proveedores/listar_clientes_factura/0"
                                      : "/proveedores/filtro/0"
                                  } ?empresa=${usuario.rucempresa}&search=${
                                    e.target.value
                                  }&estado=${"CLIENTE"}&tabla=ventas`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }
                                setclientesData(res.data.data);
                              } catch (error) {}
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  <IconButton
                                    size="small"
                                    disabled={editar}
                                    aria-label=""
                                    onClick={async () => {
                                      // seteditarProveedor(true);
                                      setOpenProveedor(true);
                                    }}
                                  >
                                    <AddCircleOutlineTwoTone color="primary" />
                                  </IconButton>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      disabled
                      className={clases.formD25}
                      id="venNomCliente"
                      label="Cliente"
                      value={venNomCliente}
                      name="venNomCliente"
                      error={error.venNomCliente}
                      onChange={(e) => handleChange(e)}
                      InputProps={{
                        endAdornment: (
                          <React.Fragment>
                            {item.venIdCliente && (
                              <IconButton
                                size="small"
                                disabled={editar}
                                aria-label=""
                                onClick={async () => {
                                  const res = await clienteAxios(
                                    `/proveedores/${item.venIdCliente}`
                                  );
                                  console.log({ res });
                                  if (!res?.data?.data[0]) {
                                    return mostrarAlerta(
                                      "Error al editar cliente",
                                      "error"
                                    );
                                  }
                                  changeObjetoActivoStateProveedor(
                                    res.data.data[0]
                                  );
                                  seteditarProveedor(true);
                                  setOpenProveedor(true);
                                }}
                              >
                                <Create color="primary" />
                              </IconButton>
                            )}
                          </React.Fragment>
                        ),
                      }}
                    />
                    <TextField
                      disabled={
                        editar &&
                        (ObjectoActivo.venEstado === "ANULADA" ||
                          ObjectoActivo.venEstado === "AUTORIZADO")
                      }
                      className={clases.formD25}
                      id="venOtrosDetalles"
                      label="Otros"
                      value={venOtrosDetalles}
                      name="venOtrosDetalles"
                      error={error.venOtrosDetalles}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem disabled value="">
                        Seleccione.
                      </MenuItem>
                      {otrosData.map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                  </>
                  {/* <Box
                className={clases.formAllW}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Divider style={{ width: "42%" }} />
                <Typography
                  style={{ width: "100%", marginTop: ".8rem" }}
                  variant="subtitle1"
                  color="primary"
                  align="center"
                  component={"span"}
                >
                  PAGO
                </Typography>
                <Divider style={{ width: "42%" }} />
              </Box> */}
                  <>
                    {usuario.rucempresa === "JEFF2023" ? (
                      <>
                        <TextField
                          type="number"
                          className={clases.formD25}
                          label="Kilometraje Inicio"
                          value={venKilometrajeInicio}
                          name="venKilometrajeInicio"
                          error={error.venKilometrajeInicio}
                          onChange={(e) =>
                            setitem({
                              ...item,
                              [e.target.name]: Math.max(0, e.target.value),
                              venKilometrajeFinal: Math.max(
                                e.target.value,
                                venKilometrajeFinal
                              ),
                            })
                          }
                        />
                        <TextField
                          type="number"
                          className={clases.formD25}
                          label="Kilometraje Final"
                          value={venKilometrajeFinal}
                          name="venKilometrajeFinal"
                          error={error.venKilometrajeFinal}
                          onChange={(e) =>
                            setitem({
                              ...item,
                              [e.target.name]: Math.max(
                                venKilometrajeInicio,
                                e.target.value
                              ),
                            })
                          }
                        />
                      </>
                    ) : null}
                    <TextField
                      disabled={
                        editar &&
                        (ObjectoActivo.venEstado === "ANULADA" ||
                          ObjectoActivo.venEstado === "AUTORIZADO")
                      }
                      className={clases.formD25}
                      id="venFormaPago"
                      label="Pago"
                      value={venFormaPago}
                      name="venFormaPago"
                      error={error.venFormaPago}
                      onChange={(e) => {
                        const formPorcentaje = formasDePago.find(
                          (formaDePago) => {
                            return formaDePago.fpagoNombre === e.target.value;
                          }
                        ).fpagoPorcentaje;
                        const resProductos = venProductos.map((productoMap) => {
                          return calcularProducto({
                            producto: productoMap,
                            formPorcentaje,
                            iva: usuario.iva,
                          });
                        });

                        const {
                          venSubTotal12,
                          venSubtotal0,
                          venDescuento,
                          venSubTotal,
                          venTotalIva,
                          venTotal,
                          venCostoProduccion,
                        } = calcularTotales(resProductos);
                        setitem({
                          ...item,
                          venProductos: resProductos,
                          formPorcentaje,
                          venFormaPago: e.target.value,
                          venFacturaCredito:
                            e.target.value !== "EFECTIVO" ? "SI" : "NO",
                          venSubTotal12,
                          venSubtotal0,
                          venDescuento,
                          venSubTotal,
                          venTotalIva,
                          venTotal,
                          venCostoProduccion,
                        });
                      }}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione </MenuItem>
                      {formasDePago.map((item) => {
                        return (
                          <MenuItem key={item.fpagoId} value={item.fpagoNombre}>
                            {item.fpagoNombre}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    {venFormaPago !== "EFECTIVO" && venFormaPago !== "" ? (
                      <TextField
                        disabled={
                          editar &&
                          (ObjectoActivo.venEstado === "ANULADA" ||
                            ObjectoActivo.venEstado === "AUTORIZADO")
                        }
                        type="number"
                        className={clases.formD25}
                        id="venNumero"
                        label="Número"
                        value={venNumero}
                        name="venNumero"
                        error={error.venNumero}
                        onChange={(e) => handleChange(e)}
                      />
                    ) : null}
                    <FormControl
                      component="fieldset"
                      className={clases.formD25}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{ marginRight: ".5rem", fontWeight: "bold" }}
                          className={
                            error.venFacturaCredito ? clases.error : ""
                          }
                        >
                          Crédito
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="Factura/Crédito"
                          value={venFacturaCredito}
                          name="venFacturaCredito"
                        >
                          <FormControlLabel
                            disabled={editar}
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            disabled={editar}
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    {venFacturaCredito === "SI" ? (
                      <TextField
                        disabled={editar}
                        tyoe="number"
                        className={clases.formD25}
                        id="venDias"
                        label="Dias"
                        value={venDias}
                        name="venDias"
                        error={error.venDias}
                        onChange={(e) => handleChange(e)}
                      />
                    ) : null}
                    <TextField
                      type="number"
                      disabled={editar}
                      className={clases.formD25}
                      id="venAbono"
                      label="Abono $"
                      value={venAbono}
                      name="venAbono"
                      error={error.venAbono}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <TextField
                      type="number"
                      disabled={
                        editar &&
                        (ObjectoActivo.venEstado === "ANULADA" ||
                          ObjectoActivo.venEstado === "AUTORIZADO")
                      }
                      className={clases.formD25}
                      id="venDescPorcentaje"
                      label="Desc %"
                      putProps={{ style: { paddingTop: "0px" } }}
                      value={venDescPorcentaje}
                      name="venDescPorcentaje"
                      error={error.venDescPorcentaje}
                      onChange={(e) => {
                        const resProductos = venProductos.map((productoMap) => {
                          return calcularProducto({
                            producto: {
                              ...productoMap,
                              descPorcentaje: e.target.value,
                            },
                            formPorcentaje: item.formPorcentaje,
                            iva: usuario.iva,
                          });
                        });
                        const {
                          venSubTotal12,
                          venSubtotal0,
                          venDescuento,
                          venSubTotal,
                          venTotalIva,
                          venTotal,
                          venCostoProduccion,
                        } = calcularTotales(resProductos);
                        setitem({
                          ...item,
                          venProductos: resProductos,
                          venDescPorcentaje: e.target.value,
                          venSubTotal12,
                          venSubtotal0,
                          venDescuento,
                          venSubTotal,
                          venTotalIva,
                          venTotal,
                          venCostoProduccion,
                        });
                      }}
                    />
                    <TextField
                      type="date"
                      disabled={
                        (editar &&
                          (ObjectoActivo.venEstado === "ANULADA" ||
                            ObjectoActivo.venEstado === "AUTORIZADO")) ||
                        (usuario.rucempresa === "TE2021" &&
                          usuario.usuario !== "admin")
                      }
                      className={clases.formD25}
                      id="venFechaFactura"
                      label="Fecha"
                      value={venFechaFactura}
                      name="venFechaFactura"
                      error={error.venFechaFactura}
                      inputProps={{
                        max: new Date(Date.now()).toISOString().substr(0, 10),
                        min:
                          usuario.rucempresa === "TE2021"
                            ? new Date(
                                new Date(Date.now()).setDate(
                                  new Date().getDate() - 1
                                )
                              )
                                .toISOString()
                                .substr(0, 10)
                            : new Date(
                                new Date(Date.now()).setMonth(
                                  new Date().getMonth() - 3
                                )
                              )
                                .toISOString()
                                .substr(0, 10),
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <TextField
                      multiline
                      rows={1}
                      disabled={
                        // !(
                        //   item.venEstado === "ANULADA" &&
                        //   ObjectoActivo.venEstado === "AUTORIZADO"
                        // )
                        false
                      }
                      className={clases.formD25}
                      id="venObservacion"
                      label="Observación"
                      value={venObservacion.replace("DESDEPELUQUERIA", "")}
                      name="venObservacion"
                      error={error.venObservacion}
                      onChange={(e) => {
                        if (e.nativeEvent.inputType === "insertLineBreak") {
                          return;
                        }
                        handleChange(e);
                      }}
                    />
                    <FormControl className={clases.formD25}>
                      <Typography
                        variant="subtitle1"
                        color="secondary"
                        style={{ fontSize: "1.8rem" }}
                      >
                        ${venTotal}
                      </Typography>
                    </FormControl>
                  </>

                  {(ObjectoActivo.venOption === "4" ||
                    ObjectoActivo.venOption === "3") &&
                  ObjectoActivo.venTipoDocumento === "NC" ? (
                    <>
                      <Divider className={clases.formAllW} />
                      <Box
                        display={"flex"}
                        justifyContent={"space-around"}
                        className={clases.formAllW}
                      >
                        <TextField
                          disabled={editar || ObjectoActivo.deshabilitarCampos}
                          className={clases.formD25}
                          id="venNumFacturaAnterior"
                          label="Factura Afectar"
                          value={item.venNumFacturaAnterior}
                          name="venNumFacturaAnterior"
                          error={error.venNumFacturaAnterior}
                          onChange={(e) => handleChange(e)}
                        />
                        <TextField
                          disabled={editar || ObjectoActivo.deshabilitarCampos}
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          className={clases.formD25}
                          id="fechaSustentoFactura"
                          label="F. Factura"
                          value={item.fechaSustentoFactura}
                          name="fechaSustentoFactura"
                          error={error.fechaSustentoFactura}
                          onChange={(e) => handleChange(e)}
                        />
                        {/* <Typography
                        style={{ width: "100%" }}
                        variant="subtitle1"
                        color="secondary"
                        align="center"
                        component={"span"}
                      >
                        FACTURA AFECTAR #: 
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          component={"span"}
                        >
                          {item.venNumFacturaAnterior}
                        </Typography>
                      </Typography>
                      <Typography
                        style={{ width: "100%" }}
                        variant="subtitle1"
                        color="secondary"
                        align="center"
                        component={"span"}
                      >
                        FECHA FACTURA: 
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          component={"span"}
                        >
                          {item.fechaSustentoFactura}
                        </Typography>
                      </Typography> */}
                      </Box>
                      <Divider className={clases.formAllW} />
                    </>
                  ) : null}
                  <FormControl className={clases.formD25}>
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="subtitle1"
                        color={error.venEmailCliente ? "error" : "primary"}
                      >
                        Correos:
                      </Typography>
                      <IconButton
                        aria-label=""
                        size="small"
                        onClick={() => {
                          const newCorreos = item.venEmailCliente;
                          newCorreos.push("");
                          setitem({
                            ...item,
                            venEmailCliente: newCorreos,
                          });
                        }}
                      >
                        <AddCircle fontSize="small" color="secondary" />
                      </IconButton>
                    </Box>
                  </FormControl>
                  {venEmailCliente.map((correo, index) => {
                    return (
                      <TextField
                        className={clases.formD25}
                        id=""
                        value={venEmailCliente[index]}
                        onChange={(e) => {
                          setitem({
                            ...item,
                            venEmailCliente: venEmailCliente.map(
                              (correoItem, indexItem) => {
                                if (indexItem === index) {
                                  return e.target.value;
                                } else {
                                  return correoItem;
                                }
                              }
                            ),
                          });
                        }}
                        type="email"
                        label={`Correo #${index}`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton
                                aria-label=""
                                onClick={() => {
                                  setitem({
                                    ...item,
                                    venEmailCliente: venEmailCliente.filter(
                                      (item, indexItem) => {
                                        return indexItem !== index;
                                      }
                                    ),
                                  });
                                }}
                              >
                                <Delete color="error" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    );
                  })}
                  {/* <Box
                className={clases.formAllW}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Divider style={{ width: "42%" }} />
                <Typography
                  style={{ width: "100%" }}
                  variant="subtitle1"
                  color="primary"
                  align="center"
                  component={"span"}
                >
                  PRODUCTOS
                </Typography>
                <Divider style={{ width: "42%" }} />
              </Box> */}
                  <Box
                    p={0}
                    maxHeight={"40vh"}
                    overflow={"scroll"}
                    width={"100%"}
                  >
                    <Paper className={clases.root}>
                      <ToolBarTable
                        titulo={"Productos"}
                        etiqueta={
                          usuario.rucempresa === "TE2021"
                            ? "Producto"
                            : "Código Barra"
                        }
                        inputData={codigoBarra}
                        setinputData={setcodigoBarra}
                        inputRef={inputRef}
                        funcionInputKey={async (e) => {
                          if (codigoBarra === "") {
                            return mostrarAlerta(
                              "Ingrese un cóodigo de barra",
                              "error"
                            );
                          }
                          if (e.key === "Enter") {
                            if (usuario.rucempresa === "TE2021") {
                              return inputRef2.current.select();
                            }
                            try {
                              const res = await clienteAxios.get(
                                `/inventario/filtro/0?search=${e.target.value}&tipo=codigobarra`
                              );

                              setcodigoBarra("");
                              setproducto(res.data.data);
                              setfocusCantidad(true);
                              const productoRes = res.data.data;
                              const newValue =
                                productoRes.invNombre +
                                "/+/" +
                                productoRes.invSerie +
                                "/+/" +
                                productoRes.invStock +
                                "/+/" +
                                productoRes.invIncluyeIva +
                                "/+/" +
                                productoRes.invTipo +
                                "/+/" +
                                productoRes.invIva;

                              const options = newValue.split("/+/");

                              setItemRow({
                                ...itemRow,
                                descripcion: options[0],
                                codigo: options[1],
                                incluyeIva: options[3],
                                llevaIva: options[5],
                                valorUnitario: productoRes.invprecios[0],
                                valUnitarioInterno: productoRes.invprecios[0],
                                descPorcentaje: item.venDescPorcentaje,
                              });

                              setagregarProducto(true);
                            } catch (error) {}
                          }
                        }}
                        etiqueta2={"Monto: $"}
                        inputData2={cantidadGasolinaDinero}
                        setinputData2={setcantidadGasolinaDinero}
                        inputRef2={inputRef2}
                        funcionInputKey2={async (e) => {
                          if (codigoBarra === "") {
                            return mostrarAlerta(
                              "Ingrese un cóodigo de barra",
                              "error"
                            );
                          }
                          if (e.key === "Enter") {
                            try {
                              const existe = venProductos.find(
                                (item) => item.codigo === codigoBarra
                              );
                              if (existe) {
                                return mostrarAlerta(
                                  "Este producto ya se encuentra listado",
                                  "error"
                                );
                              }
                              const res = await clienteAxios.get(
                                `/inventario/filtro/0?search=${codigoBarra}&tipo=codigobarra`
                              );
                              console.log({ res });

                              setcodigoBarra("");
                              // setproducto(res.data.data);
                              // setfocusCantidad(true);
                              const productoRes = res.data.data;
                              const newValue =
                                productoRes.invNombre +
                                "/+/" +
                                productoRes.invSerie +
                                "/+/" +
                                productoRes.invStock +
                                "/+/" +
                                productoRes.invIncluyeIva +
                                "/+/" +
                                productoRes.invTipo +
                                "/+/" +
                                productoRes.invIva;

                              console.log("hola");

                              const options = newValue.split("/+/");
                              const resProductos = [
                                {
                                  ...itemRow,
                                  descripcion: options[0],
                                  codigo: options[1],
                                  incluyeIva: options[3],
                                  llevaIva: options[5],
                                  valorUnitario: productoRes.invprecios[0],
                                  valUnitarioInterno: productoRes.invprecios[0],
                                  descPorcentaje: item.venDescPorcentaje,
                                  cantidad: (
                                    cantidadGasolinaDinero /
                                    productoRes.invprecios[0]
                                  ).toFixed(4),
                                },
                                ...venProductos,
                              ].map((productoMap) => {
                                return calcularProducto({
                                  producto: productoMap,
                                  formPorcentaje: item.formPorcentaje,
                                  iva: usuario.iva,
                                });
                              });

                              const {
                                venSubTotal12,
                                venSubtotal0,
                                venDescuento,
                                venSubTotal,
                                venTotalIva,
                                venTotal,
                                venCostoProduccion,
                              } = calcularTotales(resProductos);
                              console.log(resProductos);

                              setitem({
                                ...item,
                                venProductos: resProductos,
                                formPorcentaje: item.formPorcentaje,

                                venSubTotal12,
                                venSubtotal0,
                                venDescuento,
                                venSubTotal,
                                venTotalIva,
                                venTotal,
                                venCostoProduccion,
                              });
                              setcantidadGasolinaDinero(0);
                              inputRef.current.focus();
                              // setagregarProducto(true);
                            } catch (error) {
                              console.log({ error });
                            }
                          }
                        }}
                        soloTitulo={true}
                        botonAdd={usuario.rucempresa !== "TE2021"}
                        deshabilitarbotonAdd={
                          editar &&
                          (ObjectoActivo.venEstado === "ANULADA" ||
                            ObjectoActivo.venEstado === "AUTORIZADO")
                        }
                        funcionAdd={() => {
                          setagregarProducto(true);

                          // const retenciones = [
                          //   {
                          //     detTipo: "",
                          //     detCodigo: "",
                          //     detBasImponible: "",
                          //     detImpuesto: "",
                          //     detPorcentaje: "",
                          //     detValor: "",
                          //   },
                          //   ...item.retenciones,
                          // ];

                          // setitem({
                          //   ...item,
                          //   retenciones,
                          // });
                        }}
                      />
                      <TableContainer className={clases.container}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          id="tabla"
                          size="small"
                        >
                          {/* <ModalCarnet setopen={setopen} onChangeCarnetActivo={onChangeCarnetActivo}/> */}

                          <TableHead>
                            <TablaCabecera
                              columns={
                                desdePeluqueria ||
                                item.venObservacion.includes("DESDEPELUQUERIA")
                                  ? [
                                      {
                                        id: "subCategoria",
                                        label: "subCategoria",
                                        minWidth: 100,
                                        tipo: "string",

                                        propiedad: "subCategoria",
                                      },
                                      {
                                        id: "Producto",
                                        label: "Producto",
                                        minWidth: 300,
                                        tipo: "string",

                                        propiedad: "Producto",
                                      },
                                      {
                                        id: "Código",
                                        label: "Código",
                                        minWidth: 100,
                                        tipo: "string",

                                        propiedad: "Código",
                                      },
                                      {
                                        id: "incluye iva",
                                        label: "incluye iva",
                                        minWidth: 50,
                                        tipo: "string",

                                        propiedad: "incluye iva",
                                      },
                                      {
                                        id: "IVA",
                                        label: "IVA",
                                        minWidth: 50,
                                        tipo: "string",

                                        propiedad: "IVA",
                                      },
                                      {
                                        id: "Cantidad",
                                        label: "Cantidad",
                                        minWidth: 25,
                                        tipo: "string",

                                        propiedad: "Cantidad",
                                      },
                                      {
                                        id: "Precio",
                                        label: "Precio",
                                        minWidth: 75,
                                        tipo: "string",

                                        propiedad: "Precio",
                                      },
                                      {
                                        id: "Desc%",
                                        label: "Desc%",
                                        minWidth: 25,
                                        tipo: "string",

                                        propiedad: "Desc%",
                                      },
                                      {
                                        id: "Costo",
                                        label: "Costo",
                                        minWidth: 25,
                                        tipo: "string",

                                        propiedad: "Costo",
                                      },
                                    ]
                                  : [
                                      {
                                        id: "Producto",
                                        label: "Producto",
                                        minWidth: 300,
                                        tipo: "string",

                                        propiedad: "Producto",
                                      },
                                      {
                                        id: "Código",
                                        label: "Código",
                                        minWidth: 100,
                                        tipo: "string",

                                        propiedad: "Código",
                                      },
                                      {
                                        id: "incluye iva",
                                        label: "incluye iva",
                                        minWidth: 50,
                                        tipo: "string",

                                        propiedad: "incluye iva",
                                      },
                                      {
                                        id: "IVA",
                                        label: "IVA",
                                        minWidth: 50,
                                        tipo: "string",

                                        propiedad: "IVA",
                                      },
                                      {
                                        id: "Cantidad",
                                        label: "Cantidad",
                                        minWidth: 25,
                                        tipo: "string",

                                        propiedad: "Cantidad",
                                      },
                                      {
                                        id: "Precio",
                                        label: "Precio",
                                        minWidth: 75,
                                        tipo: "string",

                                        propiedad: "Precio",
                                      },
                                      {
                                        id: "Desc%",
                                        label: "Desc%",
                                        minWidth: 25,
                                        tipo: "string",

                                        propiedad: "Desc%",
                                      },
                                      {
                                        id: "Costo",
                                        label: "Costo",
                                        minWidth: 25,
                                        tipo: "string",

                                        propiedad: "Costo",
                                      },
                                    ]
                              }
                              habilitarOrdenar={false}
                            />
                          </TableHead>

                          <TableBody component={"div"}>
                            {agregarProducto ? (
                              <RowAgregarProducto
                                formPorcentaje={item.formPorcentaje}
                                venProductos={venProductos}
                                formasDePago={formasDePago}
                                setagregar={setagregarProducto}
                                setitem={setitem}
                                item={item}
                                agregar={agregarProducto}
                                setopenInventario={setopenInventario}
                                NotaCredito={
                                  (ObjectoActivo.venOption === "4" &&
                                    ObjectoActivo.venTipoDocumento === "NC") ||
                                  estadoRequest === "NOTA CREDITOS"
                                }
                                producto={producto}
                                setproducto={setproducto}
                                itemRow={itemRow}
                                setItemRow={setItemRow}
                                setfocusCantidad={setfocusCantidad}
                                focusCantidad={focusCantidad}
                                inputRef={inputRef}
                                desdePeluqueria={desdePeluqueria}
                                subcategoriasData={subcategoriasData}
                                seteditarInventario={seteditarInventario}
                                changeObjetoActivoStateInventario={
                                  changeObjetoActivoStateInventario
                                }
                                socket={socket}
                              />
                            ) : null}
                            {venProductos
                              .sort((a, b) => a.order - b.order)
                              .map((row, index) => {
                                return (
                                  <RowProducto
                                    formPorcentaje={item.formPorcentaje}
                                    venProductos={venProductos}
                                    subcategoriasData={subcategoriasData}
                                    setopenInventario={setopenInventario}
                                    formasDePago={formasDePago}
                                    deshabilitarbotones={
                                      editar &&
                                      (ObjectoActivo.venEstado === "ANULADA" ||
                                        ObjectoActivo.venEstado ===
                                          "AUTORIZADO") &&
                                      ObjectoActivo.venOption !== "4" &&
                                      ObjectoActivo.venTipoDocumento !== "NC"
                                    }
                                    item={item}
                                    setitem={setitem}
                                    row={row}
                                    index={index}
                                    key={index}
                                    NotaCredito={
                                      (ObjectoActivo.venOption === "4" &&
                                        ObjectoActivo.venTipoDocumento ===
                                          "NC") ||
                                      estadoRequest === "NOTA CREDITOS"
                                    }
                                    desdePeluqueria={desdePeluqueria}
                                    seteditarInventario={seteditarInventario}
                                    changeObjetoActivoStateInventario={
                                      changeObjetoActivoStateInventario
                                    }
                                    handleDragStart={handleDragStart}
                                    handleDragEnter={handleDragEnter}
                                    handleDragEnd={handleDragEnd}
                                    socket={socket}
                                  />
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Box>
                  <>
                    <Box display="flex" mt={0} width={"100%"} flexWrap="wrap">
                      <Box className={clases.formD25}>
                        <Divider className={clases.formAllW} />
                        <Box
                          display={"flex"}
                          justifyContent={"space-around"}
                          className={clases.formAllW}
                        >
                          <Typography
                            style={{ width: "100%" }}
                            variant="subtitle1"
                            color="secondary"
                            align="center"
                            component={"span"}
                          >
                            DINERO $:
                            <Typography
                              variant="subtitle1"
                              color="primary"
                              component={"span"}
                            >
                              <TextField
                                type="number"
                                id=""
                                label=""
                                value={dinero}
                                onChange={(e) => {
                                  setdinero(e.target.value);
                                }}
                                variant="outlined"
                                InputProps={{
                                  endAdornment: (
                                    <React.Fragment>
                                      <MonetizationOn color="primary" />
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            </Typography>
                          </Typography>
                          <Typography
                            style={{ width: "100%" }}
                            variant="subtitle1"
                            color="secondary"
                            align="center"
                            component={"span"}
                          >
                            CAMBIO $:
                            <Typography variant="subtitle1" color="primary">
                              {(dinero - (venTotal - venAbono)).toFixed(2)}
                            </Typography>
                          </Typography>
                        </Box>
                        <Divider className={clases.formAllW} />
                      </Box>

                      <TableContainer
                        className={clases.formD25}
                        component={Paper}
                      >
                        <Table size="small" aria-label="a dense table">
                          <TableBody>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={clases.paddingLeft}
                              >
                                Subtotal {usuario.iva}%
                              </TableCell>
                              <TableCell
                                align="right"
                                className={clases.font500}
                              >
                                ${venSubTotal12}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={clases.paddingLeft}
                              >
                                Subtotal 0%
                              </TableCell>
                              <TableCell
                                align="right"
                                className={clases.paddingRight}
                              >
                                ${venSubtotal0}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={clases.paddingLeft}
                              >
                                Descuento
                              </TableCell>
                              <TableCell
                                align="right"
                                className={clases.paddingRight}
                              >
                                ${venDescuento}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={clases.paddingLeft}
                              >
                                Subtotal
                              </TableCell>
                              <TableCell
                                align="right"
                                className={clases.paddingRight}
                              >
                                ${venSubTotal}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={clases.paddingLeft}
                              >
                                Iva {usuario.iva}%
                              </TableCell>
                              <TableCell
                                align="right"
                                className={clases.paddingRight}
                              >
                                ${venTotalIva}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={clases.paddingLeft}
                              >
                                Abono
                              </TableCell>
                              <TableCell
                                align="right"
                                className={clases.paddingRight}
                              >
                                ${venAbono}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={clases.font500}
                              >
                                Total
                              </TableCell>
                              <TableCell
                                align="right"
                                className={clases.font500}
                              >
                                ${(venTotal - venAbono).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </>
                </Box>
                {/* <Divider /> */}
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                ></Box>
              </div>
            </Box>
          </Draggable>
        </Box>
      </Modal>
    </>
  );
};

export default memo(ModalAsistencia);

import React, { memo, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";

import { Cancel, Check, Create } from "@mui/icons-material";

import { useState } from "react";

import AuthContext from "../../../../Context/Auth/AuthContext";

import { Download } from "@mui/icons-material";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import CampoDoc from "../../../components/Campos/CampoDoc";
import { CallModalPersona } from "../../../components/Tablas/CallNombrePersona";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  celda: {
    fontSize: ".7rem",
  },
  ocultar: { display: "none" },
}));

const RowAgregar = (props) => {
  // eslint-disable-next-line
  const {
    bancos,
    ccPago,
    item,
    setitem,
    index,
    socket,
    tienePermiso,
    alertaPermiso,
  } = props;
  const [itemEdit, setItemEdit] = useState({ ...ccPago });
  const [editar, seteditar] = useState(false);
  const [cargando, setcargando] = useState(false);
  const classes = useRowStyles();
  //gql eliminar user
  const { usuario } = useContext(AuthContext);
  const handleEditar = async (enviarCorreo) => {
    try {
      socket.current.emit("client:actualizarData", {
        ...item,
        enviarCorreo,
        ccPagos: item.ccPagos.map((ccPago, indexccPago) => {
          if (index === indexccPago) {
            return { ...itemEdit, ccUsuario: usuario.usuario, editado: true };
          } else {
            return ccPago;
          }
        }),
        tabla: "cuentasporcobrar",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        ccUser: usuario.usuario,
        ccEmpresa: usuario.rucempresa,
      });
    } catch (error) {}
  };
  const handleChange = (e) => {
    setItemEdit({
      ...itemEdit,
      [e.target.name]: e.target.value,
    });
  };

  const botonDisabled = () => {
    if (
      itemEdit.ccTipo === "" ||
      itemEdit.ccDeposito === "" ||
      itemEdit.ccValor === "" ||
      Number(itemEdit.ccValor) <= 0 ||
      itemEdit.ccFechaAbono === ""
    ) {
      return true;
    } else {
      return false;
    }
  }; // PARA EL DOCUMENTO

  useEffect(() => {
    setItemEdit({ ...ccPago });
    // eslint-disable-next-line
  }, [item]);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [openCDCorreo, setopenCDCorreo] = useState(false);
  const handleEliminar = () => {
    if (!tienePermiso("eliminar")) {
      return alertaPermiso("eliminar");
    }
    socket.current.emit("client:actualizarData", {
      ...item,
      ccPagos: item.ccPagos.filter((item, indexccago) => {
        return indexccago !== index;
      }),
      tabla: "cuentasporcobrar",
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      ccUser: usuario.usuario,
      ccEmpresa: usuario.rucempresa,
    });
  };
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Item?"
        contenido={`El Item $} será eliminado, no podrá ser recuperado.`}
        _id={index}
        funcion={handleEliminar}
      />
      <ConfirmacionDialog
        open={openCDCorreo}
        setopen={setopenCDCorreo}
        titulo={`¿Desea notificar al cliente sobre el pago realizado?`}
        _id={index}
        funcion={() => {
          handleEditar(true);
          seteditar(false);
          setItemEdit(ccPago);
        }}
        funcionNo={() => {
          handleEditar(false);
          seteditar(false);
          setItemEdit(ccPago);
        }}
      />
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <FormControlLabel
              className={classes.margin}
              onChange={(e) => {
                setitem({
                  ...item,
                  ccPagos: item.ccPagos.map((CPPago, indexCPPago) => {
                    if (index === indexCPPago) {
                      return { ...itemEdit, imprimir: e.target.checked };
                    } else {
                      return CPPago;
                    }
                  }),
                });
              }}
              control={
                <Checkbox
                  disabled={!editar}
                  value="ACTIVO"
                  className={classes.margin}
                  checked={itemEdit.imprimir}
                  color="secondary"
                />
              }
            />
            {editar ? (
              <>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    seteditar(false);

                    setItemEdit({ ...ccPago });
                  }}
                >
                  <Cancel fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  color={
                    botonDisabled
                      ? botonDisabled()
                        ? "inherit"
                        : "secondary"
                      : "secondary"
                  }
                  disabled={botonDisabled() || cargando}
                  className={classes.margin}
                  onClick={() => {
                    setopenCDCorreo(true);
                  }}
                >
                  <Check fontSize="small" />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  className={classes.margin}
                  disabled={item.ccEstado === "ANULADA"}
                  color={item.ccEstado === "ANULADA" ? "inherit" : "primary"}
                  onClick={() => {
                    if (!tienePermiso("editar")) {
                      return alertaPermiso("editar");
                    }
                    seteditar(true);
                  }}
                >
                  <Create fontSize="small" />
                </IconButton>
              </>
            )}
          </Box>
        </TableCell>

        {/* las demas columnas */}
        {editar ? (
          <>
            {" "}
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <CampoDoc
                disabled={cargando}
                disabledE={cargando}
                setcargando={setcargando}
                label={"Documento"}
                propiedad={"ccComprobante"}
                item={itemEdit}
                setitem={setItemEdit}
                celda={true}
                claseCelda={classes.celda}
                extension={"all"}
              />
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={itemEdit.ccTipo}
                name="ccTipo"
                onChange={(e) => handleChange(e)}
                size="small"
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
                fullWidth
              >
                <MenuItem value="">Seleccione Tipo</MenuItem>
                {[
                  "EFECTIVO",
                  "RETENCION",
                  "CHEQUE",
                  "TRANSFERENCIA",
                  "DEPOSITO",
                  "TARJETA",
                  "CRUCE COMPRA",
                  "DONACION",
                ]
                  .sort()
                  .map((itemTipo, index) => {
                    return (
                      <MenuItem key={index} value={itemTipo}>
                        {itemTipo}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                disabled={itemEdit.ccTipo === "EFECTIVO"}
                type="number"
                inputProps={{ style: { textAlign: "center" } }}
                value={itemEdit.ccNumero}
                name="ccNumero"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                disabled={itemEdit.ccTipo === "EFECTIVO"}
                value={itemEdit.ccBanco}
                name="ccBanco"
                onChange={(e) => handleChange(e)}
                size="small"
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione Banco</MenuItem>
                {bancos.map((ccPago) => {
                  return (
                    <MenuItem key={ccPago.banId} value={ccPago.banNombre}>
                      {ccPago.banNombre}
                    </MenuItem>
                  );
                })}
              </TextField>
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                disabled={itemEdit.ccTipo === "EFECTIVO"}
                value={itemEdit.ccDeposito}
                name="ccDeposito"
                onChange={(e) => handleChange(e)}
                size="small"
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
                fullWidth
              >
                <MenuItem value="">Seleccione Tipo</MenuItem>
                {["SI", "NO", "NINGUNO"].map((itemccDeposito, index) => {
                  return (
                    <MenuItem key={index} value={itemccDeposito}>
                      {itemccDeposito}
                    </MenuItem>
                  );
                })}
              </TextField>
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="date"
                value={itemEdit.ccFechaAbono}
                name="ccFechaAbono"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                inputProps={{ style: { textAlign: "center" } }}
                value={itemEdit.ccValor}
                name="ccValor"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={itemEdit.ccDetalle}
                name="ccDetalle"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {usuario.usuario}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {itemEdit.ccComprobante === "" ||
              !itemEdit.ccComprobante ? null : (
                <Tooltip title="Descargar Archivo: Documento" arrow>
                  <Box>
                    <IconButton
                      color="primary"
                      onClick={async () => {
                        window.open(itemEdit.ccComprobante);
                      }}
                      aria-label=""
                      size="small"
                    >
                      <Download fontSize="small" />
                    </IconButton>
                  </Box>
                </Tooltip>
              )}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {ccPago.ccTipo}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {ccPago.ccNumero}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {ccPago.ccBanco}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {ccPago.ccDeposito}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {ccPago.ccFechaAbono.substr(0, 10)}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              $
              {ccPago.ccValor
                ? Number(ccPago.ccValor).toFixed(2)
                : ccPago.ccValor}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {ccPago.ccDetalle}
            </TableCell>
            <CallModalPersona
              user={ccPago.ccUsuario}
              claseCelda={classes.celda}
            />
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);

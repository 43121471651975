import { useTheme } from "@emotion/react";
import { MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useModalStyle } from "../../../../styles/styleModal";
import CampoDoc from "../../Campos/CampoDoc";
export const CampoDinamico = ({
  // campo,
  name,
  label,
  tipo,
  required,
  opciones,
  // order,
  // id,
  item,
  setitem,
  textoPropiedad,
  cargando,
  setcargando,
  error,
}) => {
  const clases = useModalStyle();
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStylesMenuItems(name, permisos = [], theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor:
        permisos.indexOf(name) === -1 ? "white" : theme.palette.primary.light,
    };
  }
  const onChange = (e) => {
    setitem({
      ...item,
      [textoPropiedad]: {
        ...item[textoPropiedad],
        [e.target.name]: e.target.value,
      },
    });
  };
  if (tipo === "TEXTO") {
    return (
      <TextField
        error={error[name]}
        size="small"
        className={clases.formD}
        type="text"
        label={label}
        name={name}
        required={required === "SI"}
        value={item[textoPropiedad][name]}
        onChange={onChange}
      />
    );
  }
  if (tipo === "NUMERICO") {
    return (
      <TextField
        error={error[name]}
        size="small"
        className={clases.formD}
        type="number"
        label={label}
        name={name}
        required={required === "SI"}
        value={item[textoPropiedad][name]}
        onChange={onChange}
      />
    );
  }
  if (tipo === "MULTISELECT") {
    return (
      <>
        <TextField
          error={error[name]}
          size="small"
          className={clases.formD}
          label={label}
          name={name}
          required={required === "SI"}
          value={item[textoPropiedad][name] ? item[textoPropiedad][name] : []}
          onChange={onChange}
          select
          MenuProps={MenuProps}
          SelectProps={{
            displayEmpty: true,
            multiple: true,
            renderValue: (selected) => {
              return selected
                .map((value, index) => {
                  return value;
                })
                .join(", ");
            },
          }}
        >
          <MenuItem disabled value="">
            Seleccione.
          </MenuItem>
          {opciones.map((opcion) => {
            return (
              <MenuItem
                key={opcion.id}
                style={getStylesMenuItems(
                  opcion.value,
                  item[textoPropiedad][name] ? item[textoPropiedad][name] : [],
                  theme
                )}
                value={opcion.value}
              >
                {opcion.value}
              </MenuItem>
            );
          })}
        </TextField>
      </>
    );
  }
  if (tipo === "SELECT") {
    return (
      <TextField
        error={error[name]}
        size="small"
        className={clases.formD}
        label={label}
        name={name}
        required={required === "SI"}
        value={item[textoPropiedad][name] ? item[textoPropiedad][name] : ""}
        onChange={onChange}
        select
        InputLabelProps={{ shrink: true }}
        SelectProps={{ displayEmpty: true }}
      >
        <MenuItem value="">Seleccione.</MenuItem>
        {opciones.map((opcion) => {
          return (
            <MenuItem
              key={opcion.id}
              style={getStylesMenuItems(
                [opcion.value],
                item[textoPropiedad][name] ? item[textoPropiedad][name] : [],
                theme
              )}
              value={opcion.value}
            >
              {opcion.value}{" "}
            </MenuItem>
          );
        })}
      </TextField>
    );
  }
  if (tipo === "IMAGEN") {
    return (
      <CampoDoc
        error={error}
        extension="IMAGEN"
        disabledE={cargando}
        setcargando={setcargando}
        label={label}
        propiedad={name}
        item={item[textoPropiedad]}
        setitem={(e) => {
          onChange({ target: { name, value: e[name] } });
        }}
        celda={false}
      />
    );
  }
  if (tipo === "ARCHIVO") {
    return (
      <CampoDoc
        error={error}
        disabledE={cargando}
        setcargando={setcargando}
        label={label}
        propiedad={name}
        item={item[textoPropiedad]}
        setitem={(e) => {
          onChange({ target: { name, value: e[name] } });
        }}
        celda={false}
      />
    );
  }
  return null;
};
// Solo se puede eliminar un tab si no tiene campos
import React, { memo, useState, useContext, useEffect, useRef } from "react";

import { Box, Button, Divider } from "@mui/material";

import Tabla from "./Componentes/Tabla";
import Modal from "./Componentes/Modal";
import ModalB from "../../../components/Extra/ModalB";
import BarraTablas from "../../../components/BarraTablas/BarraTablas";

import AlertaContext from "../../../Context/Alerta/AlertaContext";
import ModalProveedor from "../Proveedores/Personas_Contable/Componentes/Modal";
import ModalATS from "./Componentes/ModalATS";
import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
import io from "socket.io-client";
import { socketUrl } from "../../../config/const";
const Comprobantes = (props) => {
  // const { llamadoDeOtroLugar, setopenMT } = props;
  const {
    documento,
    settab,
    setarrayExportHijos,
    desdeOtroLugarPersona,
    itemRelacionadoPadre,
  } = props;

  // DATA DEL STATE
  useEffect(() => {
    if (desdeOtroLugarPersona) {
      settab("");
      setarrayExportHijos(arrayExport.map((item) => item[campoExport]));
    }
    // eslint-disable-next-line
  }, [arrayExport]);
  const [rows, setrows] = useState(null);
  const columns = [
    {
      id: "retencion",
      label: "Retención",
      minWidth: 100,
      tipo: "string",
      orden: 3,
      propiedad: "retencion",
    },
    {
      id: "estado",
      label: "Estado",
      minWidth: 100,
      tipo: "string",
      orden: 3,
      propiedad: "estado",
      variante: "autocompleteComun",
      data: [
        "AUTORIZADO",
        "ANULADA",
        "SIN AUTORIZAR",
        "GENERAR RETENCION",
        "PROCESADO",
        "GASTOS PERSONALES",
        "IMPORTADO",
      ],
    },

    {
      id: "fechaAutorizacion",
      label: "Fec. Autorización",
      minWidth: 50,
      tipo: "fecha",
      orden: 2,
      propiedad: "fechaAutorizacion",
    },
    {
      id: "valTotalRetencion",
      label: "Ret Valor",
      minWidth: 50,
      tipo: "numero",
      orden: 1,
      propiedad: "valTotalRetencion",
    },
    {
      id: "rucProveedor",
      label: "Proveedor",
      minWidth: 50,
      tipo: "string",
      orden: 3,
      propiedad: "rucProveedor",
      variante: "autocompleteDinamico",
      propiedadesMostrar: 2,
      campoData1: "perDocNumero",
      campoData2: "perNombre",
      getData: async (data) => {
        if (data.length < 3) {
          return;
        }
        const res = await clienteAxios.get(
          `/proveedores/filtro/0?empresa=${
            usuario.rucempresa
          }&search=${data}&estado=${"PROVEEDOR"}&tabla=compras`
        );
        return res;
      },
    },
    {
      id: "factura",
      label: "Factura",
      minWidth: 100,
      tipo: "string",
      orden: 3,
      propiedad: "factura",
    },
    {
      id: "fechaFactura",
      label: "Fec. Fact",
      minWidth: 50,
      tipo: "fecha",
      orden: 2,
      propiedad: "fechaFactura",
    },
    {
      id: "valorFactura",
      label: "F. valor",
      minWidth: 50,
      tipo: "numero",
      orden: 1,
      propiedad: "valorFactura",
    },
    {
      id: "envio",
      label: "Envio",
      minWidth: 50,
      tipo: "string",
      orden: 3,
      propiedad: "envio",
      variante: "autocompleteComun",
      data: [
        "AUTORIZADO",
        "PROCESADO",
        "GASTOS PERSONALES",
        "IMPORTADO",
        "ANULADA",
        "GENERAR XML",
      ],
    },
  ];

  const defaultFullBuscador = () => {
    const json = {};
    columns.forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });

    return json;
  };

  const [ObjectoActivo, setObjetoActivo] = useState({
    empRegimen: "",
    rucEmpresa: "",
    rucProveedor: "",
    nomProveedor: "",
    factura: "",
    fDocumento: "",
    fechaFactura: `${new Date().getFullYear()}-${(
      "0" +
      (new Date().getMonth() + 1)
    ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
    valorFactura: "",
    identificacionReceptor: "",
    tipoCompra: "",
    obligado: "",
    observacion: "",
    credito: "",
    dias: "",
    fPago: "",
    retencion: "",
    rDocumento: "",
    fechaAutorizacion: "",
    errorAutorizacion: "",
    retenciones: [],
    valTotalRetencion: "",
    estado: "GENERAR RETENCION",
    inventario: "NO",
    envio: "NO",
    user: "",
    empRuc: "",
    empNombre: "",
    empComercial: "",
    empDireccion: "",
    empTelefono: "",
    empEmail: "",
    dirProveedor: "",
    emailProveedor: [],
    telProveedor: "",
    celProveedor: "",
    fPagoCodigo: "",
    fPagoDescripcion: "",
    susTributarioCodigo: "",
    susTributario: "",
  });
  const [pagination, setpagination] = useState(null);
  // FUNCIONES STATE
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    const res = await clienteAxios.get(
      `/compras/?cantidad=${cantidad}&page=${page}&search=${
        search ? search : ""
      }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
        datos ? datos : ""
      }&rucempresa=${rucempresa ? rucempresa : ""}&estado=${
        estado ? estado : ""
      }&documento=${documento ? documento : ""}`
    );

    return res;
  };

  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
    // dispatch({
    //   type: OBTENER,
    //   payload: {
    //     results: res.data.data.results,
    //     pagination: res.data.data.pagination,
    //   },
    // });
  };
  const agregarState = async (item) => {
    try {
      setrows([item, ...rows]);

      setpagination({
        ...pagination,
        numRows: pagination.numRows + 1,
      });
    } catch (error) {}
  };

  const editarState = async (item) => {
    try {
      const resRows = rows.map((itemR) => {
        if (itemR.compId === item.compId) {
          return item;
        } else {
          return itemR;
        }
      });

      setrows(resRows);
    } catch (error) {}
  };

  const eliminarState = async (_id) => {
    try {
      const resRows = rows.filter((row) => {
        return row.compId !== _id;
      });
      setrows(resRows);
      setpagination({
        ...pagination,
        numRows: pagination.numRows - 1,
      });
    } catch (error) {}
  };
  const eliminarSeleccionState = async (_ids) => {
    const resRows = rows.filter((row) => {
      return !_ids.some((item) => {
        return item === row.compId;
      });
    });
    setpagination({
      ...pagination,
      numRows: pagination.numRows - _ids.length,
    });
    setrows(resRows);
  };

  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  const rowsImportState = (data) => {
    setpagination({
      ...pagination,
      numRows: data.res.data.data.pagination.numRows,
    });
    setrows(data.res.data.data.results);
  };
  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // FUNCIONES SOCKET
  const [tabla] = useState("compra");
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });
  const socket = useRef();
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  // useEffect(() => {
  //   socket.current.on("server:guardadoExitoso", (data) => {
  //     setitemSocket({
  //       tipo: "agregar",
  //       item: data,
  //     });
  //   });
  //   socket.current.on("server:actualizadoExitoso", (data) => {
  //     setitemSocket({
  //       tipo: "editar",
  //       item: data,
  //     });
  //   });
  //   socket.current.on("server:eliminadoExitoso", (data) => {
  //     setitemSocket({
  //       tipo: "eliminar",
  //       item: data,
  //     });
  //   });
  //   socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
  //     setitemSocket({
  //       tipo: "eliminarSeleccion",
  //       item: data,
  //     });
  //   });

  //   // eslint-disable-next-line
  // }, []);
  useEffect(() => {
    const { item, tipo } = itemSocket;
    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== tabla && item.tabla !== "descagar_xml_factura_compra") {
      return;
    }

    if (desdeOtroLugarPersona && item.rucProveedor !== documento) {
      return;
    }

    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item.compId);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);

  const {
    usuario: { rucempresa, codigo },
    usuario,
  } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [arrayExport, setarrayExport] = useState([]);
  const [campoExport] = useState("compId");
  const [estadoRequest, setestadoRequest] = useState("PENDIENTES");
  const [openProveedor, setopenProveedor] = useState(false);
  const [editarProveedor, seteditarProveedor] = useState(false);
  const [openATS, setopenATS] = useState(false);
  const [open, setopen] = useState(false);
  const [buscando, setbuscando] = useState(false);
  const [editar, seteditar] = useState(false);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [input, setinput] = useState({
    id: "fechaFactura",
    label: "ID",
    minWidth: 150,
    tipo: "string",
    orden: 3,

    propiedad: "fechaFactura",
  });

  const [orden, setorden] = useState(false);
  const [openMB, setopenMB] = useState(false);
  const [fullBuscador, setfullBuscador] = useState({});
  //pagina actual
  const [page, setPage] = React.useState(0);
  //filas por pagina
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // input
  //
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );

    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };

  const handleSearch = async () => {
    setcargando(true);
    setPage(0);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setcargando(false);
  };
  useEffect(() => {
    const fullBRes = defaultFullBuscador();

    setfullBuscador(fullBRes);
    // eslint-disable-next-line
  }, []);
  const funcionDefault = async () => {
    setcargando(true);
    const fullBRes = defaultFullBuscador();
    setfullBuscador(fullBRes);
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      orden,
      JSON.stringify(fullBRes),
      rucempresa,
      estadoRequest
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };
  const funcArchivo = async (e) => {
    if (!e.target.files[0]) {
      return;
    } else {
      if (e.target.files[0].type !== "text/plain") {
        return mostrarAlerta("El archivo no es de texto");
      } else {
        setcargando(true);
        const formDataLogos = new FormData();
        formDataLogos.append("archivo", e.target.files[0]);
        formDataLogos.append("tipo", "compras");
        formDataLogos.append("rucempresa", rucempresa);
        formDataLogos.append("codigo", codigo);
        const res = await clienteAxios.post(
          "/uploads_comprastxt",
          formDataLogos
        );
        rowsImportState({ res });
        setcargando(false);
      }
    }
  };

  const funcArchivoTxtSri = async (e) => {
    if (!e.target.files[0]) {
      return;
    } else {
      if (e.target.files[0].type !== "text/plain") {
        return mostrarAlerta("El archivo no es de texto");
      } else {
        setcargando(true);
        const formDataLogos = new FormData();
        formDataLogos.append("archivo", e.target.files[0]);
        formDataLogos.append("tipo", "compras");
        formDataLogos.append("rucempresa", rucempresa);
        formDataLogos.append("codigo", codigo);
        const res = await clienteAxios.post(
          "/uploads_comprastxt",
          formDataLogos
        );
        rowsImportState({ res });
        setcargando(false);
      }
    }
  };
  const funcArchivoXmlSri = async (e) => {
    if (e.target.files.length === 0) return;
    try {
      const existeUnNoXML = Object.values(e.target.files).some(
        (file) => file.type !== "text/xml"
      );
      if (existeUnNoXML) {
        return mostrarAlerta("El archivo no es XML");
      } else {
        setcargando(true);
        const formDataLogos = new FormData();
        Object.values(e.target.files).forEach((file, index) => {
          formDataLogos.append(`archivo${index + 1}`, file);
        });
        formDataLogos.append("tipo", "compras");
        formDataLogos.append("rucempresa", rucempresa);
        formDataLogos.append("codigo", codigo);

        const res = await clienteAxios.post(
          "/uploads_xml/factura",
          formDataLogos
        );
        mostrarAlerta(res.data.msg, "success");
        setcargando(false);
      }
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error al subir el archivo XML", "error");
    }
  };
  const [ObjectoActivoProveedor, setObjetoActivoProveedor] = useState({
    perISPContratos: [],
    perNombreComercial: "",
    perEmpresa: [],
    perPais: "",
    perProvincia: "",
    perCanton: "",
    perTipoProveedor: "BIENES",
    perTiempoCredito: "0",
    perDocTipo: "",
    perDocNumero: "",
    perPerfil: [],
    perNombre: "",
    perDireccion: "",
    perObligado: "NO",
    perCredito: "NO",
    perTelefono: "0",
    perCelular: ["+593"],
    perEstado: "ACTIVO",
    perObsevacion: "",
    perEmail: ["sin@sincorreo.com"],
    perOtros: ["ZZZ9999"],
    perNickname: "",
    perUser: "",
    perFecReg: "",
    perFoto: "",
    perFecContrato: "",
    perFecInstalacion: "",
    perDiaCobro: "1",
    perTipoPlan: "",
    perNombrePlan: "",
    perValorPlan: "",
    perServicioAdicional: "0",
    perDescuentos: "0",
    perFacturaAutomatica: "NO",
    perVendedor1: {
      perId: "",
      perDocNumero: "",
      perNombre: "",
    },
    perVendedor2: {
      perId: "",
      perDocNumero: "",
      perNombre: "",
    },
    perObservacionIsp: "",
    perTipoDocumento: "NOTA",
    perTecnico1: {
      perId: "",
      perDocNumero: "",
      perNombre: "",
    },
    perTecnico2: {
      perId: "",
      perDocNumero: "",
      perNombre: "",
    },
    perMegas: "",
    perUbicacion: {
      longitud: "",
      latitud: "",
    },
    perDocumento: "",
    perGenero: "M",
    perRecomendacion: "OTROS",
    perFecNacimiento: "",
    perEspecialidad: "",
    perTitulo: "",
    perSenescyt: "",
    perPersonal: "INTERNO",
  });
  const changeObjetoActivoStateProveedor = (item) => {
    setObjetoActivoProveedor(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  return (
    <>
      <Box>
        <ModalATS open={openATS} setopen={setopenATS} />
        <ModalProveedor
          estadoRequest={"PROVEEDOR"}
          open={openProveedor}
          setopen={setopenProveedor}
          editar={editarProveedor}
          seteditar={seteditarProveedor}
          socket={socket}
          ObjectoActivo={ObjectoActivoProveedor}
          changeObjetoActivoState={changeObjetoActivoStateProveedor}
        />
        <BarraTablas
          search={search}
          setsearch={setsearch}
          funcion={handleSearch}
          buscando={buscando}
          setbuscando={setbuscando}
          setopen={(boolean) => {
            if (desdeOtroLugarPersona) {
              setObjetoActivo({
                ...ObjectoActivo,

                rucProveedor: itemRelacionadoPadre.perDocNumero,
                nomProveedor: itemRelacionadoPadre.perNombre,
              });
            }
            setopen(boolean);
          }}
          open={open}
          openMB={openMB}
          setopenMB={setopenMB}
          LabelBuscador="Buscar por todos los campos"
          funcionDefault={funcionDefault}
          ats={true}
          setopenATS={setopenATS}
          funcArchivo={funcArchivo}
          funcArchivoTxtSri={funcArchivoTxtSri}
          funcArchivoXmlSri={funcArchivoXmlSri}
          subirArchivoCompras={true}
          ocultarAgregar={estadoRequest === "ANULADAS"}
          ocultarMigasDePan={desdeOtroLugarPersona}
          autorizarPorLote={async () => {
            try {
              setcargando(true);
              const res = await clienteAxios.post("/compras");
              mostrarAlerta(res.data.msg, "success");
              funcionDefault();
            } catch (error) {
              setcargando(false);
              mostrarAlerta("Hubo un error", "error");
            }
          }}
          descargarDocumentosDesdeSri={async () => {
            try {
              setcargando(true);
              const res = await clienteAxios.post(
                "/descargarDocumentosDesdeSri/xmlFactura"
              );
              mostrarAlerta(res.data.data, "success");
              funcionDefault();
            } catch (error) {
              setcargando(false);
              mostrarAlerta("Hubo un error", "error");
            }
          }}
        />
        <ModalB
          defaultFullBuscador={defaultFullBuscador}
          openMB={openMB}
          setopenMB={setopenMB}
          titulo={`Busqueda avanzada (Compras: ${estadoRequest})`}
          fullBuscador={fullBuscador}
          setfullBuscador={setfullBuscador}
          arrayProps={columns}
          handleChange={handleChange}
          funcionB={funcionB}
          buscando={buscando}
          funcionDefault={funcionDefault}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems=" stretch"
          width="100%"
        >
          <Box>
            <Button
              size="small"
              variant={estadoRequest === "PENDIENTES" ? "contained" : null}
              onClick={async () => {
                setcargando(true);
                await remplazarRowsState(
                  rowsPerPage,
                  0,
                  "",
                  input.id,
                  orden,
                  JSON.stringify(fullBuscador),
                  rucempresa,
                  "PENDIENTES"
                );

                setPage(0);
                setestadoRequest("PENDIENTES");
                setcargando(false);
              }}
              disableElevation
              style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
              color="primary"
            >
              PENDIENTES
            </Button>
            <Button
              size="small"
              variant={estadoRequest === "PROCESADAS" ? "contained" : null}
              onClick={async () => {
                setcargando(true);
                await remplazarRowsState(
                  rowsPerPage,
                  0,
                  "",
                  input.id,
                  orden,
                  JSON.stringify(fullBuscador),
                  rucempresa,
                  "PROCESADAS"
                );

                setPage(0);
                setestadoRequest("PROCESADAS");
                setcargando(false);
              }}
              disableElevation
              style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
              color="primary"
            >
              PROCESADAS
            </Button>
            <Button
              size="small"
              variant={estadoRequest === "SIN AUTORIZAR" ? "contained" : null}
              onClick={async () => {
                setcargando(true);
                await remplazarRowsState(
                  rowsPerPage,
                  0,
                  "",
                  input.id,
                  orden,
                  JSON.stringify(fullBuscador),
                  rucempresa,
                  "SIN AUTORIZAR"
                );

                setPage(0);
                setestadoRequest("SIN AUTORIZAR");
                setcargando(false);
              }}
              disableElevation
              style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
              color="primary"
            >
              SIN AUTORIZAR
            </Button>
            <Button
              size="small"
              variant={estadoRequest === "ANULADAS" ? "contained" : null}
              onClick={async () => {
                setcargando(true);
                await remplazarRowsState(
                  rowsPerPage,
                  0,
                  "",
                  input.id,
                  orden,
                  JSON.stringify(fullBuscador),
                  rucempresa,
                  "ANULADAS"
                );

                setPage(0);
                setestadoRequest("ANULADAS");
                setcargando(false);
              }}
              disableElevation
              style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
              color="primary"
            >
              ANULADAS
            </Button>
          </Box>
          <Box>
            <Button
              size="small"
              variant={
                estadoRequest === "GASTOS PERSONALES" ? "contained" : null
              }
              onClick={async () => {
                setcargando(true);
                await remplazarRowsState(
                  rowsPerPage,
                  0,
                  "",
                  input.id,
                  orden,
                  JSON.stringify(fullBuscador),
                  rucempresa,
                  "GASTOS PERSONALES"
                );

                setPage(0);
                setestadoRequest("GASTOS PERSONALES");
                setcargando(false);
              }}
              disableElevation
              style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
              color="secondary"
            >
              GASTOS PERSONALES
            </Button>
          </Box>
        </Box>
        <Divider />
        <Tabla
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          search={search}
          input={input}
          orden={orden}
          setinput={setinput}
          setorden={setorden}
          seteditar={seteditar}
          setopen={setopen}
          rows={rows}
          buscando={buscando}
          cargando={cargando}
          setcargando={setcargando}
          fullBuscador={fullBuscador}
          estadoRequest={estadoRequest}
          // STATE
          addRowsState={addRowsState}
          getDataState={getDataState}
          remplazarRowsState={remplazarRowsState}
          obtenerState={obtenerState}
          pagination={pagination}
          columns={columns}
          ObjectoActivo={ObjectoActivo}
          changeObjetoActivoState={changeObjetoActivoState}
          rowsImportState={rowsImportState}
          // export
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          socket={socket}
        />
        {socket.current ? (
          <Modal
            editar={editar}
            seteditar={seteditar}
            setopen={setopen}
            open={open}
            seteditarProveedor={seteditarProveedor}
            setopenProveedor={setopenProveedor}
            ObjectoActivo={ObjectoActivo}
            changeObjetoActivoState={changeObjetoActivoState}
            changeObjetoActivoStateProveedor={changeObjetoActivoStateProveedor}
            socket={socket}
            ObjectoActivoProveedor={ObjectoActivoProveedor}
          />
        ) : null}
      </Box>
    </>
  );
};

export default memo(Comprobantes);

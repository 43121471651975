import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Table,
  TableBody,
  Button,
  Badge,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { tienePermisoRuta } from "../../../../config/validaciones";
import MenuContext from "../../../../Context/Menu/MenuContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { permisoDenagado, trimPropsItem } from "../../../../config/const";
import { AddCircle } from "@mui/icons-material";
import RowItemRaza from "./RowItemsRazas/RowItemRaza";
import RowAgregarItemRaza from "./RowItemsRazas/RowAgregarItemRaza";
import TablaCabecera from "./TablaCabecera";
import RowAgregarItemAlimento from "./RowItemsAlimentos/RowAgregarItemAlimento";
import RowItemAlimento from "./RowItemsAlimentos/RowItemAlimento";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    puedeExportar,
    desdeOtroLugar,
    socket,
    tienePermiso,
    alertaPermiso,
  } = props;
  const [agregarItemLista, setagregarItemLista] = useState(false);
  const [expandir, setexpandir] = useState(false);
  const { usuario } = useContext(AuthContext);
  const { rows: rowsMenu } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [itemPadre, setitemPadre] = useState(row);
  const [editar, seteditar] = useState(false);
  const classes = useRowStyles();
  const [seccion, setseccion] = useState({
    razas: true,
    alimentos: false,
  });
  const { razas, alimentos } = seccion;
  const handleEliminar = async (_id) => {
    if (!tienePermiso("eliminar")) {
      return alertaPermiso("eliminar");
    }

    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla: "especie",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        espUser: usuario.usuario,
        espEmpresa: usuario.rucempresa,
        espId: row.espId,
      });
    } catch (error) {}
  };

  const handleChange = (e) => {
    setitemPadre({
      ...itemPadre,
      [e.target.name]: e.target.value,
    });
  };
  const funcionEdit = () => {
    if (!tienePermiso("editar")) {
      return alertaPermiso("editar");
    }
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "editar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    seteditar(true);
  };

  const funcCancel = () => {
    setitemPadre(row);
    seteditar(false);
  };
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...itemPadre,
          tabla: "especie",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          espUser: `${row.espUser.split(" ** ")[0]} ** ${usuario.usuario}`,
          espEmpresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const botonDisabled = () => {
    if (itemPadre.espNombre === "") {
      return true;
    } else {
      return false;
    }
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro?`}
        _id={row._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          editar={editar}
          campoEstado=""
          puedeExportar={puedeExportar}
          desdeOtroLugar={desdeOtroLugar}
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          botonDisabled={botonDisabled}
          mostrarEspandir={true}
          setexpandir={setexpandir}
          expandir={expandir}
        />
        {editar ? (
          <>
            {" "}
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={itemPadre.espNombre}
                name="espNombre"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.espNombre}
            </TableCell>
          </>
        )}
      </TableRow>{" "}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box p={1} pt={2} sx={{ maxHeight: "30vh", overflow: "scroll" }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Badge
                    badgeContent={itemPadre.espRazas.length}
                    color="primary"
                  >
                    <Button
                      variant={razas ? "contained" : null}
                      disableElevation
                      style={{
                        padding: ".5rem",
                        borderRadius: "15px 15px  0px 0px",
                      }}
                      color="primary"
                      onClick={() => setseccion({ razas: true })}
                    >
                      Razas
                    </Button>
                  </Badge>
                  <Badge
                    badgeContent={itemPadre.espAlimentos.length}
                    color="secondary"
                  >
                    <Button
                      variant={alimentos ? "contained" : null}
                      disableElevation
                      style={{
                        padding: ".5rem",
                        borderRadius: "15px 15px  0px 0px",
                      }}
                      color="secondary"
                      onClick={() => setseccion({ alimentos: true })}
                    >
                      Alimentos
                    </Button>
                  </Badge>
                </Box>
                <IconButton
                  size="small"
                  aria-label=""
                  color="secondary"
                  onClick={() => {
                    setagregarItemLista(true);
                  }}
                >
                  <AddCircle />
                </IconButton>
              </Box>
              <Table size="small" aria-label="purchases">
                <TablaCabecera
                  columns={[
                    {
                      id: "nombre",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "nombre",
                    },
                  ]}
                />
                {razas ? (
                  <TableBody>
                    {agregarItemLista ? (
                      <RowAgregarItemRaza
                        itemPadre={itemPadre}
                        setitemPadre={setitemPadre}
                        setagregarItemLista={setagregarItemLista}
                        socket={socket}
                      />
                    ) : null}
                    {itemPadre.espRazas.map((itemRaza, index) => {
                      return (
                        <RowItemRaza
                          itemRaza={itemRaza}
                          itemPadre={itemPadre}
                          setitemPadre={setitemPadre}
                          index={index}
                          key={index}
                          socket={socket}
                        />
                      );
                    })}
                  </TableBody>
                ) : null}
                {alimentos ? (
                  <TableBody>
                    {agregarItemLista ? (
                      <RowAgregarItemAlimento
                        itemPadre={itemPadre}
                        setitemPadre={setitemPadre}
                        setagregarItemLista={setagregarItemLista}
                        socket={socket}
                      />
                    ) : null}
                    {itemPadre.espAlimentos.map((itemAlimento, indexPadre) => {
                      return (
                        <RowItemAlimento
                          itemAlimento={itemAlimento}
                          itemPadre={itemPadre}
                          setitemPadre={setitemPadre}
                          indexPadre={indexPadre}
                          key={indexPadre}
                          socket={socket}
                        />
                      );
                    })}
                  </TableBody>
                ) : null}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
